import React from 'react'

export default function Flight() {
    const headerStyle = {
        backgroundImage: "url('/images/page-title-bg.jpg')",
        // Add other styles as necessary, e.g., height, backgroundSize, etc.
      };
    
    
      
  return (
    <>
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center" style={headerStyle}>
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>Flight</h2>
              
              {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Flight</li>
          </ol>
        </div>
      </nav>
    </div>
        <div className="main">
            <div className="pagewrap modify-search">
              <div className='head-wrapper'>
                  <div className='flight-search'>
                  <div class="container">
                    <ul class="nav nav-tabs border-0" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway" type="button" role="tab" aria-controls="oneway" aria-selected="true">
                                <span class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>One-way
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="return-tab" data-bs-toggle="tab" data-bs-target="#return" type="button" role="tab" aria-controls="return" aria-selected="false" tabindex="-1">
                                <span class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>Return
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="multiCity-tab" data-bs-toggle="tab" data-bs-target="#multiCity" type="button" role="tab" aria-controls="multiCity" aria-selected="false" tabindex="-1">
                                <span class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>Multi-city
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="oneway" class="tab-pane active" role="tabpanel" aria-labelledby="oneway-tab">
                            <div class="row">
                                <div class="col-12">
                                    <div class="search-pan row mx-0 theme-border-radius">
                                        <div class="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="form-group">
                                                <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" class="form-control ps-5" id="onewayOrigin" placeholder="Origin" />
                                                <button class="pos-swap"><i class="bi bi-arrow-left-right pl-1"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="form-group">
                                                <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" class="form-control ps-5" id="onewayDestination" placeholder="Destination" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                            <div class="form-control form-group d-flex">
                                                <i class="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                <span class="dep-date-input">
                                                    <input type="text" class="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="dropdown" id="myDD">
                                                <button class="dropdown-toggle form-control" type="button" id="travellerInfoOneway" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                    <span class="text-truncate">1 Traveller(s), Economy </span>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="travellerInfoOneway">
                                                    <ul class="drop-rest">
                                                        <li>
                                                            <div class="d-flex">Select Adults</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayAdult">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayAdult">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="d-flex">Select Child</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayChild">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayChild" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayChild">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="d-flex">Select Infants</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayInfant">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayInfant">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Economy" class="me-2" />Economy </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Special" class="me-2" />Premium Economy </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Business" class="me-2" />Business </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="First" class="me-2" />First Class </label>
                                                        </li>
                                                        <li>
                                                            <button type="button" class="btn btn" onclick="">Done</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-2 px-0">
                                            <button type="submit" class="btn btn-search">
                                                <span class="fw-bold">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check-inline">
                                                <label class="check-wrap">Refundable Flights
                                                    <input type="checkbox" />
                                                    <span class="checkmark"></span> </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="check-wrap"> Non Stop Flights
                                                    <input type="checkbox" />
                                                    <span class="checkmark"></span> </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <label class="check-wrap"> GDS Special Return
                                                    <input type="checkbox" />
                                                    <span class="checkmark"></span> </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="return" class="tab-pane fade" role="tabpanel" aria-labelledby="return-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="search-pan row mx-0 theme-border-radius">
                                        <div class="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="form-group">
                                                <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" class="form-control ps-5" id="returnOrigin" placeholder="Origin" />
                                                <button class="pos-swap"><i class="bi bi-arrow-left-right pl-1"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="form-group">
                                                <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                <input type="text" class="form-control ps-5" id="returnDestination" placeholder="Destination" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                            <div class="form-control form-group d-flex">
                                                <i class="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                <span class="dep-date-input">
                                                    <input type="text" class="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker1" />
                                                </span>
                                                <span class="arv-date-input ms-2">
                                                    <input type="text" class="cal-input" placeholder="Return Date" id="datepickerNull" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2">
                                            <div class="dropdown" id="myDDReturn">
                                                <button class="dropdown-toggle form-control" type="button" id="travellerInfoReturn" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                    <span class="text-truncate">1 Traveller(s), Economy </span>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="travellerInfoReturn">
                                                    <ul class="drop-rest">
                                                        <li>
                                                            <div class="d-flex">Select Adults</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayAdult">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayAdult">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="d-flex">Select Child</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayChild">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayChild" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayChild">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="d-flex">Select Infants</div>
                                                            <div class="ms-auto input-group plus-minus-input">
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="minus" data-field="onewayInfant">
                                                                        <i class="bi bi-dash"></i>
                                                                    </button>
                                                                </div>
                                                                <input class="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                <div class="input-group-button">
                                                                    <button type="button" class="circle" data-quantity="plus" data-field="onewayInfant">
                                                                        <i class="bi bi-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Economy" class="me-2" />Economy </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Special" class="me-2" />Premium Economy </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="Business" class="me-2" />Business </label>
                                                        </li>
                                                        <li>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="class" value="First" class="me-2" />First Class </label>
                                                        </li>
                                                        <li>
                                                            <button type="button" class="btn btn" onclick="">Done</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-2 px-0">
                                            <button type="submit" class="btn btn-search" >
                                                <span class="fw-bold">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="multiCity" class="tab-pane fade" role="tabpanel" aria-labelledby="multiCity-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="search-pan row mx-0 theme-border-radius">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div class="form-group">
                                                        <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" class="form-control ps-5" id="multiOrigin" placeholder="Origin" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                                    <div class="form-group">
                                                        <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" class="form-control ps-5" id="multiDestination" placeholder="Destination" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 pe-xl-2">
                                                    <div class="form-control form-group d-flex">
                                                        <i class="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                        <span class="dep-date-input">
                                                            <input type="text" class="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker3" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-4 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-0 pe-xl-0">
                                                    <div class="dropdown" id="myDDRound">
                                                        <button class="dropdown-toggle form-control" type="button" id="travellerInfoMulti" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="bi bi-person-lines-fill position-absolute h2 icon-pos"></i>
                                                            <span class="text-truncate">1 Traveller(s), Economy </span>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="travellerInfoMulti">
                                                            <ul class="drop-rest">
                                                                <li>
                                                                    <div class="d-flex">Select Adults</div>
                                                                    <div class="ms-auto input-group plus-minus-input">
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="minus" data-field="onewayAdult">
                                                                                <i class="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input class="input-group-field" type="number" name="onewayAdult" value="0" />
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="plus" data-field="onewayAdult">
                                                                                <i class="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="d-flex">Select Child</div>
                                                                    <div class="ms-auto input-group plus-minus-input">
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="minus" data-field="onewayChild">
                                                                                <i class="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input class="input-group-field" type="number" name="onewayChild" value="0" />
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="plus" data-field="onewayChild">
                                                                                <i class="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="d-flex">Select Infants</div>
                                                                    <div class="ms-auto input-group plus-minus-input">
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="minus" data-field="onewayInfant">
                                                                                <i class="bi bi-dash"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input class="input-group-field" type="number" name="onewayInfant" value="0" />
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="circle" data-quantity="plus" data-field="onewayInfant">
                                                                                <i class="bi bi-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="class" value="Economy" class="me-2" />Economy </label>
                                                                </li>
                                                                <li>
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="class" value="Special" class="me-2" />Premium Economy </label>
                                                                </li>
                                                                <li>
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="class" value="Business" class="me-2" />Business
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="class" value="First" class="me-2" />First Class </label>
                                                                </li>
                                                                <li>
                                                                    <button type="button" class="btn btn" onclick="">Done</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-0 mt-md-0 mt-lg-0 mt-xl-2">
                                                <div class="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div class="form-group">
                                                        <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" class="form-control ps-5" id="multiOrigin2" placeholder="Origin" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-4 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                                                    <div class="form-group">
                                                        <i class="bi bi-geo-alt-fill position-absolute h2 icon-pos"></i>
                                                        <input type="text" class="form-control ps-5" id="multiDestination2" placeholder="Destination" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-4 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2">
                                                    <div class="form-control form-group d-flex">
                                                        <i class="bi bi-calendar3 position-absolute h2 icon-pos"></i>
                                                        <span class="dep-date-input">
                                                            <input type="text" class="cal-input hasDatepicker" placeholder="Depart Date" id="datepicker4" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-12 col-xl-4 px-0">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-6 col-xl-7 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center">
                                                            <button type="submit" class="btn btn-light" id="add-button">
                                                                <span class="fw-bold">+ Add City</span> </button>
                                                            <button type="submit" class="btn" id="remove-button"> <span class="fw-bold">Close</span> </button>
                                                        </div>
                                                        <div class="col-12 col-lg-6 col-xl-5">
                                                            <button type="submit" class="btn btn-search" onclick="">
                                                                <span class="fw-bold">Search</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="filter-sec py-2">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div class="fw-bold"><i class="bi bi-funnel pe-2"></i>Filters</div>
                    <div>
                        <a class="btn flt-btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Reset All
                        </a>
                    </div>
                </div>
            </div>
        </div>







        <div class="content-section">
           
            <div class="srp py-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-2">
                            <div class="d-flex justify-content-between align-items-start">
                                <div>
                                    <div class="fw-bold">Berlin (BER)<i class="bi bi-arrow-right mx-2"></i>London (LHR)
                                    </div>
                                    <div class="mb-1 font-small">Sun, Mar 30</div>
                                </div>
                                <div>
                                    <span class="font-small">Showing 118 of 118 flights.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col-12 col-md-12 d-none d-md-block">
                                    <div class="row g-0 border theme-border-radius p-2 theme-bg-accent-three">
                                        <div class="col-md-3">
                                            <span class="font-small fw-bold">Airline</span>
                                        </div>
                                        <div class="col-md-2">
                                            <span class="font-small fw-bold">Depart</span>
                                        </div>
                                        <div class="col-md-2">
                                            <span class="font-small fw-bold">Duration</span>
                                        </div>
                                        <div class="col-md-2">
                                            <span class="font-small fw-bold">Arrive</span>
                                        </div>
                                        <div class="col-md-3 text-md-center">
                                            <span class="font-small fw-bold">Price<i class="bi bi-arrow-up"></i>
                                                <input type="checkbox" class="cursor-pointer" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-md-block d-md-none">
                                    <button class="btn w-100 border theme-border-radius p-2 theme-bg-accent-three" type="button">
                                        <i class="bi bi-sliders me-2"></i><span class="visible-xs font-medium">Sort
                                            Depart</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/6E.jpg" class="img-fluid theme-border-radius" alt="Indigo" title="airline Indigo" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">IndiGo
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/airasia.jpg" class="img-fluid theme-border-radius" alt="airasia" title="airline airasia" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Airasia
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">AE - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/spicejet.jpg" class="img-fluid theme-border-radius" alt="spicejet" title="airline spicejet" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Spicejet
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">SP - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/vistara.jpg" class="img-fluid theme-border-radius" alt="vistara" title="airline vistara" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Vistara
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/thaiAir.jpg" class="img-fluid theme-border-radius" alt="thaiAir" title="airline thaiAir" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">ThaiAir
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/6E.jpg" class="img-fluid theme-border-radius" alt="Indigo" title="airline Indigo" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">IndiGo
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/airasia.jpg" class="img-fluid theme-border-radius" alt="airasia" title="airline airasia" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Airasia
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">AE - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/spicejet.jpg" class="img-fluid theme-border-radius" alt="spicejet" title="airline spicejet" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Spicejet
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">SP - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/vistara.jpg" class="img-fluid theme-border-radius" alt="vistara" title="airline vistara" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">Vistara
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div class="row g-0 border theme-border-radius theme-box-shadow p-2 align-items-center theme-bg-white">
                                <div class="col-12 col-md-3">
                                    <div class="d-flex">
                                        <div>
                                            <img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/thaiAir.jpg" class="img-fluid theme-border-radius" alt="thaiAir" title="airline thaiAir" />
                                        </div>
                                        <div class="d-flex flex-column ms-2">
                                            <span class="font-small d-inline-flex mb-0 align-middle">ThaiAir
                                            </span>
                                            <span class="font-small d-inline-flex mb-0 align-middle">6E - 315</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-small">Flight Details</a>
                                    </div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">07:20</div>
                                    <div class="font-small">BER</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="font-small">03h 15m</div>
                                    <span class="stops"></span>
                                    <div class="font-small">Non Stop</div>
                                </div>
                                <div class="col-4 col-md-2">
                                    <div class="fw-bold">08:20</div>
                                    <div class="font-small">LHR</div>
                                </div>
                                <div class="col-12 col-md-3 text-center mt-md-0 mt-2">
                                    <div class="fw-bold"><i class="bi bi-currency-dollar ms-2"></i>4755</div>
                                    <button type="submit" class="btn-select btn btn-effect" onclick="window.location.href='review-booking.html';">
                                        <span class="font-small">Select</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

                  </div>
              </div>  
            </div>
        </div>
    </>
  )
}
