import React, { useEffect, useMemo, useRef, useState } from 'react'
import './flight.css'
import { GoArrowSwitch } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { useClickedOutside } from './clickedOutsideHook';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FlightComp } from './flightMultiSearch';
import { airportSearchResultData, fareTypeData, tripTypeData } from '../data';


const Flight = () => {
  const [tripType, settripType] = useState("oneWay")
  const [fareType, setfareType] = useState("regular")
  const [from, setFrom] = useState({iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"})
  const [openFrom, setOpenFrom] = useState(false)
  const fromRef = useRef(null)
  useClickedOutside(fromRef,setOpenFrom)

  const [to, setTo] = useState({iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"})
  const [openTo, setOpenTo] = useState(false)
  const toRef = useRef(null)
  useClickedOutside(toRef,setOpenTo)

  
  const [departureDate, setDepartureDate] = useState(new Date())
  const [openDepartureDate, setOpenDepartureDate] = useState(false)
  const departureDateRef = useRef(null)
  useClickedOutside(departureDateRef,setOpenDepartureDate)
  
  const [returnDate, setReturnDate] = useState(new Date(departureDate))
  const [openReturnDate, setOpenReturnDate] = useState(false)
  const returnDateRef = useRef(null)
  useClickedOutside(returnDateRef,setOpenReturnDate)

  const [travellersAndClass, setTravellersAndClass] = useState({ class:"Economy/Premium Economy", adults:1, children:0, infants:0 })
  const [openTravellersAndClass, setOpenTravellersAndClass] = useState(false)
  const travellersAndClassRef = useRef(null)
  useClickedOutside(travellersAndClassRef,setOpenTravellersAndClass)
  const dateOptions = useMemo(() => ( { weekday: 'long', year: '2-digit', month: 'short', day: 'numeric' }), [])
  useEffect(() => {
    if(returnDate < departureDate){
      setReturnDate(new Date(departureDate))
    }  
  }, [departureDate, returnDate]);

  const [multiCityData, setMultiCityData] = useState(
    [
      {
        from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
        to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
        departureDate: new Date(departureDate)
      },
      {
        from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
        to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
        departureDate: new Date(departureDate)
      },
      // {
      //   from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
      //   to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
      //   departureDate: new Date(departureDate)
      // },
      // {
      //   from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
      //   to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
      //   departureDate: new Date(departureDate)
      // } 
  ])




  return (
    <div className='flight-main-container'>
      <div className='flight-first-box'>
        <div className='flights-tabs'>
          {tripTypeData.map((e, i)=><span key={i}> 
            <div
              onClick={()=>settripType(e.key)}
              style={e.key===tripType?{backgroundColor:"#f2f5f6"}:null}> <span style={e.key===tripType?{backgroundColor:"#133957", border:"none"}:null}></span> 
              <span>{e.text}</span>
           </div>
          </span>)}
        </div>
        <div><p>Book International and Domestic Flights</p></div>
      </div>
      <div>
        {tripType==='multiCity'?
        multiCityData.map((e,i)=>{
          return <FlightComp index={i} isLast={i===multiCityData.length -1} data={multiCityData} setData={setMultiCityData} travellersAndClass={travellersAndClass} setTravellersAndClass={setTravellersAndClass}/>
        })
        
        :
        <div className='flight-second-box'>
          
          <div className='flight-from' >
            <div onClick={()=> setOpenFrom(true)}>
              <p>From</p>
              <div>
                <h3>{from.cityName.length>12?from.cityName.slice(0,15)+"...":from.cityName}</h3>
                <p>{from.iata}, {from.airportName.length>35?from.airportName.slice(0,35)+"...":from.airportName}</p>
              </div>

            </div>
            { 
              openFrom &&
              <div className='airport-search-container' ref={fromRef}>
                <div>
                  <div id='input-box'>
                    <span><CiSearch/></span>
                    <input type="text"  onChange={(e)=>console.log(e.target.value)} placeholder='From' autoFocus={true}/>
                  </div>
                  <div className='search-result-display'>
                    <p className='search-result-display-title'>{airportSearchResultData.results?.SUGGESTIONS.title}</p>
                    {airportSearchResultData.results.SUGGESTIONS.data.map((e,i)=>{
                      return <div key={i} className='search-result-display-item'
                      onClick={()=>{
                        setFrom(e)
                        setOpenFrom(false)
                      }}
                      >
                            
                            <div className='search-result-display-city-iata'>
                              <div className='d-flex gap-2 w-100'>
                                <img src={e.icon} alt="icon" />
                                <div>
                                  <p id='search-result-display-city'>{e.cityName}</p>
                                  <p id='search-result-display-airport'>{e.airportName}</p>
                                </div>
                              </div>
                                <p id='search-result-display-iata'>{e.iata}</p>
                            </div>
                          
                            
                      </div>
                    })}
                  </div>
                </div>
              </div>
            }
          </div>

          <div className='switch-button ' onClick={()=>{
            const temp = {...to}
            setTo({...from})
            setFrom(temp)
          }}>
            <div className=''>
              <GoArrowSwitch color='#133957'/>
            </div>
          </div>

          <div className='flight-to'>
            <div onClick={()=> setOpenTo(true)}>
              <p>To</p>
              <div>
                <h3>{to.cityName.length>12?to.cityName.slice(0,15)+"...":to.cityName}</h3>
                <p>{to.iata}, {to.airportName.length>35?to.airportName.slice(0,35)+"...":to.airportName}</p>
              </div>
            </div>
            { 
              openTo &&
              <div className='airport-search-container airport-search-container-to' ref={toRef}>
                <div>
                  <div id='input-box'>
                    <span><CiSearch/></span>
                    <input type="text"  onChange={(e)=>console.log(e.target.value)} placeholder='To' autoFocus={true}/>
                  </div>
                  <div className='search-result-display'>
                    <p className='search-result-display-title'>{airportSearchResultData.results.SUGGESTIONS.title}</p>
                    {airportSearchResultData.results.SUGGESTIONS.data.map((e,i)=>{
                      return <div key={i} className='search-result-display-item'
                      onClick={()=>{
                        setTo(e)
                        setOpenTo(false)
                        }}
                      >
                            
                            <div className='search-result-display-city-iata'>
                              <div className='d-flex gap-2 w-100'>
                                <img src={e.icon} alt="icon" />
                                <div>
                                  <p id='search-result-display-city'>{e.cityName}</p>
                                  <p id='search-result-display-airport'>{e.airportName}</p>
                                </div>
                              </div>
                                <p id='search-result-display-iata'>{e.iata}</p>
                            </div>
                            
                      </div>
                    })}
                  </div>
                </div>
              </div>
            }
          </div>
          
          <div className='flight-date'>
            <div className='flight-date-departure' >
              <div onClick={()=> setOpenDepartureDate(true)}>
                <p className=''>Departure <span style={{ color:"#133957"}}><i  style={{WebkitTextStroke: '2px'}} className="bi bi-chevron-down"></i></span></p>
                <div>
                  <div className='d-flex align-items-end gap-1'>
                  <h3>{departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]}</h3>
                  <p className='fs-5'>{departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}'{departureDate.toLocaleDateString('en-US', dateOptions).split(',')[2].trim()}
                  </p>
                  </div>
                  <p>{departureDate.toLocaleDateString('en-US', dateOptions).split(',')[0]}</p>
                </div>
              </div>
              { 
                openDepartureDate &&
                <div className='airport-search-container airport-search-container-date' ref={departureDateRef}>
                  <div>
                    <div className=''>
                    <div>
                      <div>
                        
                      </div>
                      <div>
                        <Calendar 
                          // returnValue={'range'}
                          onChange={(e)=>{
                            setDepartureDate(e)
                            setOpenDepartureDate(false)
                            }} 
                          goToRangeStartOnSelect={false}
                          showNeighboringMonth={false}
                          value={departureDate} 
                          // selectRange={true} 
                          // showDoubleView={true} 
                          minDate={new Date()}
                          showFixedNumberOfWeeks={false}
                          allowPartialRange={false}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className='flight-date-departure position-relative' >
              <div onClick={()=> {
                setOpenReturnDate(true)
                if(tripType !== 'roundTrip'){
                  settripType('roundTrip')
                }
              }
              }>
                <p className=''>Return <span style={{ color:"#133957"}}><i  style={{WebkitTextStroke: '2px'}} className="bi bi-chevron-down"></i></span></p>
                {tripType === 'roundTrip'?
                <div>
                  <div className='d-flex align-items-end gap-1'>
                  <h3>{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]}</h3>
                  
                  <p className='fs-5'>{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}'{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[2].trim()}
                  </p>
                  </div>
                  <p>{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[0]}</p>
                  <div className='return-close' >
                    <div className='' onClick={()=>settripType("oneWay")}><i class="bi bi-x-circle-fill"></i></div>
                  </div>
                </div>
                :
                <div>
                  <p style={{fontSize:'.8rem', fontWeight:"600",color:"rgb(110, 108, 108)", lineHeight:"1rem"}}>Tap to add a return date for bigger discounts</p>
                </div>
              }
              </div>
              { 
                openReturnDate && tripType === 'roundTrip' &&
                <div className='airport-search-container airport-search-container-date' ref={returnDateRef}>
                  <div>
                    <div className=''>
                    <div>
                      <div>
                        
                      </div>
                      <div>
                        <Calendar 
                          onChange={(e)=>{
                            setReturnDate(e)
                            setOpenReturnDate(false)
                            }} 
                          goToRangeStartOnSelect={false}
                          showNeighboringMonth={false}
                          value={returnDate} 
                          minDate={departureDate}
                          showFixedNumberOfWeeks={false}
                          allowPartialRange={false}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>


          
          </div>

          <div className='flight-travellers'>
            <div onClick={()=> setOpenTravellersAndClass(true)}>
              <p>Travellers & Class <span style={{ color:"#133957"}}><i  style={{WebkitTextStroke: '2px'}} className="bi bi-chevron-down"></i></span></p>
              <div>
                  <div className='d-flex align-items-end gap-1'>
                  <h3>{travellersAndClass.adults+travellersAndClass.children+travellersAndClass.infants}</h3>
                  
                  <p className='fs-5'>Travellers
                  </p>
                  </div>
                  <p>{travellersAndClass.class}</p>
              </div>
            </div>
            { 
              openTravellersAndClass &&
              <div className='airport-search-container airport-search-container-travellers' ref={travellersAndClassRef}>
                <div>
                  <div>
                    <p id='flight-travellers-title'>ADULTS (12y +)</p> 
                    <p id='flight-travellers-subtitle'> on the day of travel</p>
                  </div>
                  <div id='flight-travellers-numbers'>
                    {[1,2,3,4,5,6,7,8,9].map((e,i)=>{
                      return <span key={i} 
                      onClick={()=>setTravellersAndClass(pre=>({...pre,adults:e}))}
                      style={e===travellersAndClass.adults ? {backgroundColor:"#133957",color:'#fff'}:null}>{e}</span>
                    })}
                    <span 
                    style={'>9'===travellersAndClass.adults ? {backgroundColor:"#133957",color:'#fff'}:null}
                    onClick={()=>setTravellersAndClass(pre=>({...pre,adults:'>9'}))}>&gt;9</span>
                  </div>
                <div className='d-flex gap-5 mt-3'>
                  <div>
                    <div>
                      <p id='flight-travellers-title'>CHILDREN (2y - 12y )</p> 
                      <p id='flight-travellers-subtitle'>on the day of travel</p>
                    </div>
                    <div id='flight-travellers-numbers'>
                      {[1,2,3,4,5,6].map((e,i)=>{
                        return <span key={i} 
                        onClick={()=>setTravellersAndClass(pre=>({...pre,children:e}))}
                        style={e===travellersAndClass.children ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
                      })}
                      <span 
                      style={'>6'===travellersAndClass.children ? {backgroundColor:"#e03a3c"}:null}
                      onClick={()=>setTravellersAndClass(pre=>({...pre,children:'>6'}))}>&gt;6</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p id='flight-travellers-title'>NFANTS (below 2y)</p> 
                      <p id='flight-travellers-subtitle'>on the day of travel</p>
                    </div>
                    <div id='flight-travellers-numbers'>
                      {[1,2,3,4,5,6].map((e,i)=>{
                        return <span key={i} 
                        onClick={()=>setTravellersAndClass(pre=>({...pre,infants:e}))}
                        style={e===travellersAndClass.infants ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
                      })}
                      <span 
                      style={'>6'===travellersAndClass.infants ? {backgroundColor:"#e03a3c",color:'#fff'}:null}
                      onClick={()=>setTravellersAndClass(pre=>({...pre,infants:'>6'}))}>&gt;6</span>
                    </div>
                  </div>
                </div>
                <div className='mt-3'>
                <p id='flight-travellers-title'>CHOOSE TRAVEL CLASS</p> 
                <div id='flight-travellers-numbers'>
                    {['Economy/Premium Economy','Premium Economy','Business'].map((e,i)=>{
                      return <span key={i} 
                      onClick={()=>setTravellersAndClass(pre=>({...pre,class:e}))}
                      style={e===travellersAndClass.class ? {backgroundColor:"#133957",color:'#fff'}:null}>{e}</span>
                    })}
                  </div>
                </div>
                <div className='mt-3 d-flex justify-content-end '><button
                  onClick={()=>setOpenTravellersAndClass(pre=>!pre)}
                className='apply-button'>APPLY</button></div>
                  
                </div>

              </div>
            }
          
          </div>

        </div>
        }
      </div>
      <div className='flight-third-box'>
        <div className='flights-tabs flights-tabs-third-box'>
          <span style={{fontSize:".8rem", }}>Select A Fare Type:</span>
          {fareTypeData.map((e, i)=><span key={i}
          className='fare-type-item'
          > 
            <div
              onClick={()=>setfareType(e.key)}
              
              style={e.key===fareType?{backgroundColor:"#f2f5f6",width:"100%"}:null}> <span style={e.key===fareType?{backgroundColor:"#133957", border:"none"}:null}></span> 
              <span className='flights-tabs-third-box-text-wrapper'>{e.text}<p>Fares</p></span>
              
           </div>
          </span>)}
        </div>
        <div><p>Book International and Domestic Flights</p></div>
      </div>

    </div>
  )
}

export default Flight