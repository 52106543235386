import React, { useMemo } from 'react'
import './dates.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

export const Dates = ({close,isMultiCity, openDepartureDate, setOpenDepartureDate,departureDate,tripType,settripType,setOpenReturnDate,returnDate,dateOptions, setReturnDate,openReturnDate,setDepartureDate}) => {
    const isMobile = useMemo(() => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i),[]) 

  return (
    <div className='mobile-dates'>
        <div className='search-main-conatiner-mobile-navigation mobile-dates-navigation'>
            <div 
                onClick={close}
                className='font-weight-bold'>
                <i class="bi bi-x"></i>
            </div> 
            <span className='font-weight-bold'>Select {openDepartureDate?"Departure":"Return"} Date</span>
        </div>

        <div style={{
            
            padding:"1rem",width:"100%",height: `${isMobile?'calc(74vh - 55px)':'72vh'}`, overflow:'auto',scrollbarWidth: "none" }}>
        {openReturnDate &&  !isMultiCity && 
            <Calendar 
                onChange={(e)=>{
                setReturnDate(e)
                setOpenReturnDate(false)
                setOpenDepartureDate(true)
                }} 
                goToRangeStartOnSelect={false}
                showNeighboringMonth={false}
                value={returnDate} 
                minDate={departureDate}
                showFixedNumberOfWeeks={false}
                allowPartialRange={false}
                className={"calendar"}
                tileContent={(e)=><p style={{fontSize:".65rem"}}></p>}
                tileClassName={"tiles"}
            />
        }
        {openDepartureDate &&   
            <Calendar 
                onChange={(e)=>{
                setDepartureDate(e)
                if(tripType==='roundTrip'){
                    setOpenReturnDate(true)

                }else{
                    close()
                }
                setOpenDepartureDate(false)
                
                }} 
                goToRangeStartOnSelect={false}
                showNeighboringMonth={false}
                value={returnDate} 
                minDate={new Date()}
                showFixedNumberOfWeeks={false}
                allowPartialRange={false}
                className={"calendar"}
                tileContent={(e)=><p style={{fontSize:".65rem"}}></p>}
                tileClassName={"tiles"}
            />
        }
        </div>

        
        <div className='date-mobile search-button-container-mobile-dates'>
                <div className='search-main-conatiner-mobile-content-common'
                    onClick={()=>{
                        setOpenDepartureDate(true) 
                        if(!isMultiCity)  
                        setOpenReturnDate(false)  
                    }}
                >
                    {/* <div><i class="bi bi-calendar-event"></i></div> */}
                    <div>
                        <p style={{color:`${openDepartureDate?"#e03a3c":"#444444"}`}}>DEPARTURE DATE</p>
                        <p><strong>{departureDate?.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]} {departureDate?.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}</strong>,<span> {departureDate?.toLocaleDateString('en-US', dateOptions).split(',')[0].split(' ')[0]} {departureDate?.toLocaleDateString('en-US', dateOptions).split(',')[2]}</span></p>
                    </div>
                </div>
                {!isMultiCity &&
                <div className='search-main-conatiner-mobile-content-common position-relative'
                    onClick={()=>{
                        if(tripType !== "roundTrip"){
                            settripType("roundTrip")
                        }
                        
                        setOpenReturnDate(true)  
                        setOpenDepartureDate(false)                  
                    }}
                >
                    {/* <div><i class="bi bi-person"></i></div> */}
                     <div>
                    {tripType === 'roundTrip'?
                    <div>
                        <p style={{color:`${openReturnDate?"#e03a3c":"#444444"}`}}>RETURN DATE</p>
                        <p><strong>{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}</strong>,<span> {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[0].split(' ')[0]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[2]}</span></p>
                        <div className='return-close' >
                            <div className='' onClick={(e)=>{
                                e.stopPropagation();
                                settripType("oneWay")
                                setOpenReturnDate(false)
                                setOpenDepartureDate(true)
                                }} style={{zIndex:1000}}><i class="bi bi-x-circle-fill"></i></div>
                        </div>
                    </div>
                        :
                        <div >
                            <p style={{color:"#e03a3c"}}>+ ADD RETURN DATE</p>
                            <p style={{fontSize:'.7rem', fontWeight:"500",color:"rgb(110, 108, 108)", lineHeight:"1rem"}}>Save more on round trip!</p>
                        </div>
                        }
                        
                    </div>
                    
                </div>
                }
        </div>

        
        <div id='search-button-container-mobile ' className='search-button-container-mobile-dates'>
                <button
                    onClick={close}
                    id='search-button-mobile'>DONE
                </button>
            </div>
    </div>
  )
}
