import React from 'react';
import Latesthotel from './Latesthotel';
//import HomeAbout from './HomeAbout';
//import TravelDestination from './TravelDestination';
import Blogsection from './Blogsection';
import Subscriber from './Subscriber';
import Search from '../../Components/search/desktop';
import SearchMobile from '../../Components/search/mobile';
export default function Home() {
  return (
    <>
  <section id="hero" className="d-flex align-items-center">
   

  </section>
  <Search />
  <SearchMobile/>
  
  <main id="main">
    <section id="clients" className="">
      <div className="container" data-aos="zoom-in">

        <Latesthotel/>
      </div>
    </section>
    {/* <HomeAbout /> */}
    {/* <TravelDestination/> */}
    <Blogsection/>
    <Subscriber/>
    

  </main>
    </>
  )
}
