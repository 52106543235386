import React from 'react'

export default function Login() {
    const headerStyle = {
        backgroundImage: "url('/images/page-title-bg.jpg')",
        // Add other styles as necessary, e.g., height, backgroundSize, etc.
      };
   
  return (
    <>
    <main id="main">
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center" style={headerStyle}>
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>Login/SignUp</h2>
              
              {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Login/SignUp</li>
          </ol>
        </div>
      </nav>
    </div>
        <section id="contact" className="contact">
            <div data-aos="fade-up" className="aos-init aos-animate">
            <div className="row">
                <div className='col-md-1'></div>
                                <div className="col-md-5">
                                <form className='php-email-form'>
                                    <h2 className="user-profile__title">Sign in</h2>
                                    <p>Access your account information and manage your bookings.</p>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value="Email *" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value="Password *" />
                                    </div>
                                    <div className="form-group">
                                        
                                        <a href="#">Forgot password</a>
                                    </div>
                                    <div className="text-center">
                                        <button type='submit'>Sign in</button>
                                    </div>
                                    </form>
                                </div>
                                <div className="col-md-5 col-md-offset-2">
                                <form className='php-email-form'>
                                    <h2 className="user-profile__title">Sign up</h2>
                                    <p>Sign up now and receive exclusive offers with huge discounts </p>
                                    <div className="form-group">
                                                <input type="text" className="form-control" value="First Name *" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" value="Last name *" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" value="Email *" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" value="Password *" />
                                            </div>
                                    
                                    <div className="text-center">
                                        <button type='submit'>Sign up</button>
                                    </div>
                                    </form>
                                </div>
                                <div className='col-md-1'></div>
                            </div>
            </div>
        </section>    
      </main>
    </>
  )
}
