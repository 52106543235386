import React, { useEffect, useMemo, useState } from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { fareTypeData, tripTypeData } from '../data'
import { Travellers } from './popups/travellers'
import { Airports } from './popups/airports'
import { Dates } from './popups/dates'
import { MultiCity } from './multiCity'
import { GoArrowSwitch } from 'react-icons/go'

const MobileSearch = () => {
    const [tripType, settripType] = useState("oneWay")
    const [fareType, setfareType] = useState(null)
    const [travellersAndClass, setTravellersAndClass] = useState({ class:"Economy/Premium Economy", adults:1, children:0, infants:0 })
    const [openTravellersAndClass, setOpenTravellersAndClass] = useState(false)
    const [from, setFrom] = useState({iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"})
    const [to, setTo] = useState({iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"})
    const [openAirports, setOpenAirports] = useState(false)
    const [autoFocusFrom, setautoFocusFrom] = useState(false)
    const [autoFocusTo, setautoFocusTo] = useState(false)


    const [departureDate, setDepartureDate] = useState(new Date())
    const [openDepartureDate, setOpenDepartureDate] = useState(false)
    const [openDates, setOpenDates] = useState(false)
    const [returnDate, setReturnDate] = useState(new Date(departureDate))
    const [openReturnDate, setOpenReturnDate] = useState(false)
    const dateOptions = useMemo(() => ( { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }), [])

    const [multiCityData, setMultiCityData] = useState(
        [

          {
            from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
            to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
            departureDate: new Date(departureDate)
          },
        //   {
        //     from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
        //     to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
        //     departureDate: new Date(departureDate)
        //   },
        //   {
        //     from:{iata:"BOM",cityName:'Mumbai',airportName:"Chhatrapati Shivaji International Airport India"},
        //     to:{iata:"GOI",cityName:'Goa - Dabolim Airport',airportName:"Goa Dabolim International Airport India"},
        //     departureDate: new Date(departureDate)
        //   } 
    ])

    const isMobile = useMemo(() => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i),[]) 
    useEffect(() => {
        if(openTravellersAndClass || openAirports || openDates){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    }, [openTravellersAndClass, openAirports, openDates])

  return (
    <div className='search-main-conatiner-mobile'>
        <div className='search-main-conatiner-mobile-navigation'>
            <Link to={'/'} className='font-weight-bold'>
                <i class="bi bi-arrow-left-short"></i>
            </Link> 
            <span className='font-weight-bold'>Flight Search</span>
        </div>
        <div className='search-main-conatiner-mobile-content'>
            <div className='search-main-conatiner-mobile-content-trip-type'>
                {tripTypeData.map((e,i)=>(
                    <span key={i} className={`${e.key===tripType?'active':null}`} 
                        onClick={()=>settripType(e.key)}
                    >
                    {e.text}
                    </span>
                ))}
            </div>
            {tripType !== "multiCity" ?
            <>
            <div className='search-main-conatiner-mobile-content-common'
                onClick={()=>{
                    setautoFocusFrom(true)
                    setautoFocusTo(false)
                    setOpenAirports(pre=>!pre)}}
            >
                <div><i class="bi bi-airplane"></i></div>
                <div>
                    <p>FROM</p>
                    <p className='search-main-conatiner-mobile-common-airport-name'><strong>{from.cityName}</strong><span> {from.iata}</span></p>
                    <p>{from.airportName}</p>
                </div>
            </div>
            <div className='switch-button-mobile' onClick={()=>{
            const temp = {...to}
            setTo({...from})
            setFrom(temp)
          }}>
            <div className=''>
              <GoArrowSwitch color='#e03a3c'/>
            </div>
          </div>

            <div className='search-main-conatiner-mobile-content-common'
                onClick={()=>{
                    setautoFocusFrom(false)
                    setautoFocusTo(true)
                    setOpenAirports(pre=>!pre)}}
            >
                <div><i class="bi bi-airplane"></i></div>
                <div>
                    <p>To</p>
                    <p className='search-main-conatiner-mobile-common-airport-name'><strong>{to.cityName}</strong><span> {to.iata}</span></p>
                    <p>{to.airportName}</p>
                </div>
            </div>

            <div className='date-mobile'>
                <div className='search-main-conatiner-mobile-content-common'
                    onClick={()=>{
                        setOpenDates(true)
                        setOpenDepartureDate(true)  
                        setOpenReturnDate(false) 
                    }}
                >
                    <div><i class="bi bi-calendar-event"></i></div>
                    <div>
                        <p>DEPARTURE DATE</p>
                        <p><strong>{departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}</strong>,<span> {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[0].split(' ')[0]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[2]}</span></p>
                    </div>
                </div>
                <div className='search-main-conatiner-mobile-content-common position-relative'
                    onClick={()=>{
                        if(tripType !== "roundTrip"){
                            settripType("roundTrip")
                        }
                        setOpenDates(true)
                        setOpenReturnDate(true)  
                        setOpenDepartureDate(false)                      
                    }}
                >
                    {/* <div><i class="bi bi-person"></i></div> */}
                    <div>
                    {tripType === 'roundTrip'?
                    <div>
                        <p>RETURN DATE</p>
                        <p><strong>{returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}</strong>,<span> {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[0].split(' ')[0]} {returnDate.toLocaleDateString('en-US', dateOptions).split(',')[2]}</span></p>
                        <div className='return-close' >
                            <div className='' onClick={(e)=>{
                                e.stopPropagation();
                                settripType("oneWay")}} style={{zIndex:1000}}><i class="bi bi-x-circle-fill"></i></div>
                        </div>
                    </div>
                        :
                        <div >
                            <p style={{color:"#e03a3c"}}>+ ADD RETURN DATE</p>
                            <p style={{fontSize:'.7rem', fontWeight:"500",color:"rgb(110, 108, 108)", lineHeight:"1rem"}}>Save more on round trip!</p>
                        </div>
                        }
                        
                    </div>
                </div>
            </div>
            </>
        :
            <MultiCity setMultiCityData={setMultiCityData} multiCityData={multiCityData}/>
            }

            <div className='search-main-conatiner-mobile-content-common'
            onClick={()=>setOpenTravellersAndClass(pre=>!pre)}
            >
                <div><i class="bi bi-person"></i></div>
                <div>
                    <p>TRAVELLER & CLASS</p>
                    <p><strong>{travellersAndClass.adults+travellersAndClass.infants+travellersAndClass.children},</strong><span> {travellersAndClass.class}</span></p>
                </div>
            </div>

            <div className='search-main-conatiner-mobile-content-fare-type' >
                <p>SPECIAL FARE (Optional))</p>
                <div>
                    {fareTypeData.map((e,i)=>(
                        <span key={i} className={`${e.key===fareType?'active':null}`} 
                            onClick={()=>setfareType(e.key)}
                        >
                        {e.text}
                        </span>
                    ))}
                </div>
            </div>

            <div id='search-button-container-mobile'>
                <button
                    id='search-button-mobile'>SEARCH FLIGHTS
                </button>
            </div>
        </div>


        {(openTravellersAndClass || openAirports || openDates) &&
        <div className='mobile-popups' style={isMobile?{height: 'calc(100vh - 55px)'}:{height: 'calc(100vh)'}}>
            <div id='mobile-popups-contents'>
            {
                openTravellersAndClass ? 
                <Travellers travellersAndClass={travellersAndClass} 
                    setTravellersAndClass={setTravellersAndClass} 
                    close={()=>setOpenTravellersAndClass(pre=>!pre)}
                />
                :
                openDates ? 
                <Dates travellersAndClass={travellersAndClass} 
                    setTravellersAndClass={setTravellersAndClass} 
                    close={()=>setOpenDates(pre=>!pre)}
                    openDepartureDate={openDepartureDate}
                    setOpenDepartureDate={setOpenDepartureDate}
                    departureDate={departureDate}
                    tripType={tripType}
                    settripType={settripType}
                    setOpenReturnDate={setOpenReturnDate}
                    returnDate={returnDate}
                    dateOptions={dateOptions}
                    setReturnDate={setReturnDate}
                    openReturnDate={openReturnDate}
                    setDepartureDate={setDepartureDate}
                    isMultiCity={false}
                />
                :

                openAirports ? <Airports
                    to={to}
                    setTo={setTo} 
                    from={from}
                    setFrom={setFrom}
                    autoFocusTo={autoFocusTo}
                    autoFocusFrom={autoFocusFrom}
                    setautoFocusFrom={setautoFocusFrom}
                    setautoFocusTo={setautoFocusTo}
                    close={()=>{
                        setautoFocusTo(pre=>!pre)
                        setautoFocusFrom(pre=>!pre)
                        setOpenAirports(pre=>!pre)}}
                />
                :
                null
            }
            </div>
        </div>
        }
        
        
    </div>
  )
}

export default MobileSearch
