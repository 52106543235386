import React, { useEffect, useMemo, useState } from 'react'
import './multiCity.css'
import { Dates } from './popups/dates'
import { Airports } from './popups/airports'

export const MultiCity = ({multiCityData, setMultiCityData}) => {
    const dateOptions = useMemo(() => ( { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }), [])
    const [openAirports, setOpenAirports] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [openDates, setOpenDates] = useState(false)
    const [autoFocusFrom, setautoFocusFrom] = useState(false)
    const [autoFocusTo, setautoFocusTo] = useState(false)
    const isMobile = useMemo(() => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i),[]) 

    useEffect(() => {
        if(openAirports || openDates){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    }, [ openAirports, openDates])

  return (
    <div className='multicity-container-mobile'>
        <div>
            <span>FROM</span>
            <span>TO</span>
            <span>DATE</span>
        </div>
        {multiCityData.map((e,i)=>{
            return <div key={i}>
                
                <div
                style={{cursor:"pointer"}}
                onClick={()=>{
                    setCurrentIndex(i)
                    setOpenAirports(true)
                }}
                >
                    {e.from.iata === null?
                    <p style={{color:"rgb(224,58,60)",paddingTop:".5rem",paddingBottom:".5rem"}}>FROM</p> :
                    <>
                        <p>{e.from.iata}</p>
                        <p>{e.from.cityName}</p>
                    </>

                }
                </div>

                <div
                style={{cursor:"pointer"}}
                onClick={()=>{
                    setCurrentIndex(i)
                    setOpenAirports(true)
                }}
                >
                {e.to.iata === null?
                    <p style={{color:"rgb(224,58,60)"}}>TO</p> :
                    <>
                        <p>{e.to.iata}</p>
                        <p>{e.to.cityName}</p>
                    </>

                }
                </div>

                <div
                style={{cursor:"pointer"}}
                onClick={()=>{
                    setCurrentIndex(i)
                    setOpenDates(true)
                }}
                >
                {e.departureDate === null?
                    <p style={{color:"rgb(224,58,60)"}}>DATE</p> :
                    <>
                    <p><strong>{e.departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[2]} {e.departureDate.toLocaleDateString('en-US', dateOptions).split(',')[1].split(' ')[1]}</strong></p>
                    <p><span> {e.departureDate.toLocaleDateString('en-US', dateOptions).split(',')[0].split(' ')[0]} {e.departureDate.toLocaleDateString('en-US', dateOptions).split(',')[2]}</span></p>
                    </>

                }

                </div>

                <div>
                    {i !== 0 &&
                        <span
                            style={{cursor:"pointer"}}
                            onClick={()=>{
                                // const newList = 
                                multiCityData.splice(i,1)
                                setMultiCityData(pre=>[...pre])
                                setCurrentIndex(0)
                            }}
                        ><i class="bi bi-x-circle-fill"></i></span>
                    }
                </div>

            </div>
        })}

        {(openAirports || openDates) &&
        <div className='mobile-popups' style={isMobile?{height: 'calc(100vh - 55px)'}:{height: 'calc(100vh)'}}>
            <div id='mobile-popups-contents'>
            {
               
                openDates ? 
                <Dates 
                    close={()=>setOpenDates(pre=>!pre)}
                    openDepartureDate={true}
                    setOpenDepartureDate={()=>{}}
                    departureDate={multiCityData[currentIndex].departureDate}
                    dateOptions={dateOptions}
                    setDepartureDate={(e)=>{
                        const newData = [...multiCityData]
                        newData[currentIndex].departureDate = e
                        setMultiCityData(newData)
                        }}
                    isMultiCity={true}
                />
                :

                openAirports ? 
                <Airports
                    to={multiCityData[currentIndex].to}
                    setTo={(e)=>{
                        const newData = [...multiCityData]
                        newData[currentIndex].to = e
                        setMultiCityData(newData)
                    }} 
                    from={multiCityData[currentIndex].from}
                    setFrom={(e)=>{
                        const newData = [...multiCityData]
                        newData[currentIndex].from = e
                        setMultiCityData(newData)
                    }}
                    autoFocusTo={autoFocusTo}
                    autoFocusFrom={autoFocusFrom}
                    setautoFocusFrom={setautoFocusFrom}
                    setautoFocusTo={setautoFocusTo}
                    close={()=>{
                        setautoFocusTo(pre=>!pre)
                        setautoFocusFrom(pre=>!pre)
                        setOpenAirports(pre=>!pre)}}
                />
                :
                null
            }
            </div>
        </div>
        }
        <div>
            <button 
            onClick={()=>setMultiCityData(pre=>[...pre, {
            from:{iata:null,cityName:null,airportName:null},
            to:{iata:null,cityName:null,airportName:null},
            departureDate: null
          },])}
            style={{marginTop:"1rem",marginBottom:"1rem",width:"100%", backgroundColor:"#fff", padding:".7rem", fontSize:".8rem", fontWeight:"bold", border:"1px dotted #e03a3c", borderRadius:".3rem",color:"#e03a3c"}}>+ ADD CITY</button>
        </div>
    </div>
  )
}
