import React, { useState } from 'react'
import './index.css'
import Flight from './flight'



const Search = () => {
  const [tabsNavigationData, setTabsNavigationData] = useState([
    {
      img:<i className="bi bi-airplane"></i>,
      text:"Flights",
      isActive: true,
      componentToRender: <Flight />
    },
    {
      img:<i className="bi bi-buildings"></i>,
      text:"Hotels",
      isActive: false,
      componentToRender: <>Hotels</>
    },
    {
      img:<i className="bi bi-houses-fill"></i>,
      text:"Packages",
      isActive: false,
      componentToRender: <>Homestays & Villas</>
    },
    {
      img:<i className="bi bi-credit-card"></i>,
      text:"Visa Checklist",
      isActive: false
    },
    {
      img:<i className="bi bi-pip"></i>,
      text:"E-Visa",
      isActive: false
    },
  ])
  return (
    <div className='search-main-container'>
        <div className='search-tabs-navigation'>
          {tabsNavigationData.map((e,i)=>{
            return <div 
            className='search-tabs-item'
              key={i}
              style={e.isActive?
              {
                borderBottom:".2rem solid #133957",
                color:"#133957",
                fontWeight:"bold"
              }
              :null}
              onClick={()=>setTabsNavigationData(pre => pre.map((ee,ii)=>(i === ii?{...ee, isActive:true}:{...ee, isActive:false})))}
            >
            {e.text === "Flights" && (
              <div>
                <div style={{ fontSize: "1.5rem" }}>
                  {e.img}
                </div>
                <p>
                  {e.text}
                </p>
                </div>
            )}
            {e.text === "Hotels" && (
        <a href="/hotel" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "Packages" && (
        <a href="/packages" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "Visa Checklist" && (
        <a href="/checklist" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
      {e.text === "E-Visa" && (
        <a href="/evisa" className='flight_text'>
          <div style={{ fontSize: "1.5rem" }}>
            {e.img}
          </div>
          <p>
            {e.text}
          </p>
        </a>
      )}
            
            </div>
            
          })}
        </div>
        <div className='search-items-main-container'>
          {tabsNavigationData.filter(e=>e.isActive)[0].componentToRender}
        </div>
        <div id='search-button-container'>
            <button
                id='search-button'>SEARCH
            </button>
        </div>

        
    </div>
  )
}

export default Search