import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  country: Yup.string().required('Please select Country'),
  state_name: Yup.string().required('Please select State'),
  city_name: Yup.string().required('Please select City'),
  category: Yup.string().required('Please select category'),
  adult: Yup.string().required('Please select adult'),
  room: Yup.string().required('Please select room'),
  numberofnight: Yup.string().required('Please select Number Of Night'),
  name: Yup.string().required('Enter Full Name'),
  email_id: Yup.string().email('Invalid email').required('Email is required'),
  phone_number: Yup.string().matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number'),
  remark: Yup.string().required('Message is required'),
});

export default function Package() {
  const headerStyle = {
    backgroundImage: "url('/images/page-title-bg.jpg')",
  };
  const [options, setOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [apiResponse, setApiResponse] = useState(null); // State for handling API response

  useEffect(() => {
    const apiUrl = 'https://chalophir.com/admin/api/CountryList';
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1 && Array.isArray(data.countryList)) {
          setOptions(data.countryList);
        } else {
          console.error('Invalid data format:', data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const fetchStates = (countryId) => {
    fetch(`https://chalophir.com/admin/api/GetStateList/${countryId}`)
      .then((response) => response.json())
      .then((data) => setStates(data.state_list))
      .catch((error) => console.error('Error fetching states:', error));
  };

  const fetchCity = (stateId) => {
    fetch(`https://chalophir.com/admin/api/GetCityList/${stateId}`)
      .then((response) => response.json())
      .then((data) => setCity(data.city_list))
      .catch((error) => console.error('Error fetching cities:', error));
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // API endpoint for submitting form data
    const apiUrl = 'https://chalophir.com/admin/api/PackagesInsert';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setApiResponse(data.message || 'Form submitted successfully!');
        resetForm(); // Optionally reset the form on success
      })
      .catch((error) => {
        setApiResponse('An error occurred. Please try again.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <main id="main">
        <div className="breadcrumbs">
          <div className="page-header d-flex align-items-center" style={headerStyle}>
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>Package</h2>
                  <p>Book Holiday Packages and start planning your enjoy trips now!</p>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <ol>
                <li><a href="/">Home</a></li>
                <li>Package</li>
              </ol>
            </div>
          </nav>
        </div>

        <section id="contact" className="contact">
          <div data-aos="fade-up" className="aos-init aos-animate">
            <div className="row">
              <div className="col-md-2"></div>

              <div className="col-md-8">
                <Formik
                  initialValues={{
                    country: '',
                    state_name: '',
                    city_name: '',
                    category: '',
                    adult: '',
                    room: '',
                    numberofnight: '',
                    name: '',
                    email_id: '',
                    phone_number: '',
                    remark: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form className="php-email-form">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="country"
                              value={values.country}
                              onChange={(e) => {
                                setFieldValue('country', e.target.value);
                                fetchStates(e.target.value);
                              }}
                            >
                              <option value="">Select Country</option>
                              {options.map((country) => (
                                <option key={country.country_id} value={country.country_id}>
                                  {country.country_name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="country" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <Field as="select" className="form-control" name="city_name" value={values.city_name}>
                              <option value="">Select City</option>
                              {city.length > 0 ? (
                                city.map((city) => (
                                  <option key={city.city_id} value={city.city_id}>
                                    {city.city_name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>Not Available</option>
                              )}
                            </Field>
                            <ErrorMessage name="city_name" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <Field as="select" className="form-control" name="adult">
                              <option value="">Adult</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </Field>
                            <ErrorMessage name="adult" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <Field as="select" className="form-control" name="room">
                              <option value="">Room</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </Field>
                            <ErrorMessage name="room" component="div" className="text-danger" />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="form-group">
                            <Field
                              as="select"
                              className="form-control"
                              name="state_name"
                              value={values.state_name}
                              onChange={(e) => {
                                setFieldValue('state_name', e.target.value);
                                fetchCity(e.target.value);
                              }}
                            >
                              <option value="">Select State</option>
                              {states.length > 0 ? (
                                states.map((state) => (
                                  <option key={state.state_id} value={state.state_id}>
                                    {state.state_name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>Not Available</option>
                              )}
                            </Field>
                            <ErrorMessage name="state_name" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <Field as="select" className="form-control" name="category">
                              <option value="">Category</option>
                              <option value="5 Star">5 Star</option>
                              <option value="4 Star">4 Star</option>
                            </Field>
                            <ErrorMessage name="category" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <Field as="select" className="form-control" name="numberofnight">
                              <option value="">Number Of Nights</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </Field>
                            <ErrorMessage name="numberofnight" component="div" className="text-danger" />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <Field type="text" className="form-control" name="name" placeholder="Name" />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>

                      <div className="form-group">
                        <Field type="text" className="form-control" name="email_id" placeholder="Email ID" />
                        <ErrorMessage name="email_id" component="div" className="text-danger" />
                      </div>

                      <div className="form-group">
                        <Field type="text" className="form-control" name="phone_number" placeholder="Phone Number +91999999999" />
                        <ErrorMessage name="phone_number" component="div" className="text-danger" />
                      </div>

                      <div className="form-group">
                        <Field as="textarea" className="form-control" name="remark" placeholder="Remarks" />
                        <ErrorMessage name="remark" component="div" className="text-danger" />
                      </div>

                      <div className="text-center">
                        <button type="submit" disabled={isSubmitting}>
                          Send Enquiry
                        </button>
                      </div>

                      {/* Display the API response message */}
                      {apiResponse && <div className="mt-3 text-success">{apiResponse}</div>}
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="col-md-2"></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
