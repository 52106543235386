import React,{ useState, useEffect } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone_number: Yup.string().matches(
      /^\+(?:[0-9] ?){6,14}[0-9]$/,
        'Invalid phone number'
      ),
    message: Yup.string().required('Message is required'),
  });
export default function Latesthotel() {
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
          const response = await fetch('https://chalophir.com/admin/api/contact_us', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          });
          if (!response.ok) {
            throw new Error('Failed to submit the form');
          }
          // Reset the form after successful submission
          resetForm();
          const successDiv = document.getElementById('successMessage');
          successDiv.innerText = 'Form submitted successfully';
          console.log('Form submitted successfully');
        } catch (error) {
          console.error('Error submitting data:', error);
        } finally {
          setSubmitting(false);
        }
      };


    const [seen, setSeen] = useState(false)

    function togglePop () {
        setSeen(!seen);
    };
    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      const enquiry_btn = {
        lineHeight: '15px',
        fontSize: '15px',
        marginTop: '0px',
        marginLeft: '0px'
      };
      const imgehei ={
        height: '266px'
      }
      const [hotel, homehotel] = useState([]);
      useEffect(() => {
        // Define your API endpoint
        const apiUrl = 'https://chalophir.com/admin/api/hotelsgetapi';
    
        fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        //console.log('API Response:', data);

      // Ensure data is an array before setting the state
      if (data.status === 1 && Array.isArray(data.hotel_list)) {
        homehotel(data.hotel_list);
      } else {
        console.error('Invalid data format:', data);
      }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <>
    <section id="popular-courses" className="courses">
      <div className="container aos-init aos-animate" data-aos="fade-up">

        <div className="section-title">
          <h2>Hot Deals</h2>
        </div>
        <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={5000} itemClass="carousel-item-padding-40-px" removeArrowOnDeviceType={["tablet", "mobile"]} >
        {hotel.map(hoteldata => (
          <div className="col-lg-12 col-md-12 d-flex align-items-stretch hotelclass" key={hoteldata.id}>
            <div className="course-item">
              <img src={hoteldata.hotel_image} className="img-fluid" alt="..." />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 data-toggle="modal" data-target="#exampleModal">Enquiry Now</h4>
                  <p className="price">INR {hoteldata.price}/Night</p>
                </div>

                <h3><a href={'hotel-details/'+hoteldata.slug}>{hoteldata.hotel_name}</a></h3>
              </div>
            </div>
          </div>
          ))}
          </Carousel>
          <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    <Formik
    initialValues={{ name: '', email: '',phone_number: '',message:'' }}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    <Form>
      <div className="form-field">
      <label htmlFor="name">Name <sup>*</sup></label>
        <Field type="text" name="name" id="name" className="form-control" />
        <ErrorMessage name="name" component="div" className="contact_error" />
      </div>
      <div className="form-field">
      <label htmlFor="name">Email ID <sup>*</sup></label>
        <Field type="text" name="email" id="email" className="form-control" />
        <ErrorMessage name="email" component="div" className="contact_error" />
      </div>
      <div className="form-field">
      <label htmlFor="name">Phone Number <sup>*</sup></label>
        <Field type="text" name="phone_number" id="phone_number" className="form-control" />
        <ErrorMessage name="phone_number" component="div" className="contact_error" />
      </div>
      <div className="form-field form-field-area">
      <label htmlFor="message">Message <sup>*</sup></label>
      <Field type="textarea" name="message" id="message" className="form-control" />
        <ErrorMessage name="message" component="div" className="contact_error" />
      </div>
      <div className="form-field text-center">
        {/* Submit button triggers form submission */}
        
      </div>
    </Form>
  </Formik>
    </div>
    <div className="modal-footer">
      <button type="submit" className="awe-btn awe-btn-2 arrow-right arrow-white awe-btn-lager">Submit</button>
    </div>
  </div>
</div>
</div>
        </div>
      </div>
    </section>
    </>
  )
}
