import React,{ useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-carousel-minimal';


export default function HotelDetail() {
    
      let { str } = useParams(); 
      const [HotelDetails, setHotelDetails] = useState([]);
      useEffect(() => {
        const fetchHotelDetails = async () => {
          try {
            const response = await fetch('https://chalophir.com/admin/api/hoteldetails/'+str);
            if (!response.ok) {
              throw new Error('Failed to fetch blog details');
            }
            const data = await response.json();
            setHotelDetails(data.hotel_details);
          } catch (error) {
            console.error('Error fetching blog details:', error);
          }
        };
        fetchHotelDetails();
      }, []);
      const data = [
        {
          image: HotelDetails.hotel_image,
        },
       
      
      
      ];
      if (HotelDetails.hotel_all_image && Array.isArray(HotelDetails.hotel_all_image)) {
        data.push(...HotelDetails.hotel_all_image.map(item => ({ image: item.hotel_images })));
      }
      const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }
  return (
    <>
        <main id="main">
            <div className="breadcrumbs aos-init aos-animate" data-aos="fade-in">
            <div className="container">
                <h2>Hotel Detail</h2>
            </div>
            </div>
            <section id="course-details" className="course-details">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-8">
          
          <Carousel
            data={data}
            time={5000}
            width="850px"
            height="300px"
            
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        
            <h3>{ HotelDetails.hotel_name }</h3>
            <address className="address">
            { HotelDetails.location }
            </address>
           <p></p>
            <p dangerouslySetInnerHTML={{ __html: HotelDetails.long_description }}></p>
          </div>
          <div className="col-lg-4">
          <div className="box-widget-item fl-wrap block_box contact">
                                        <div className="box-widget-item-header detail-spn">
                                            <h3>Book <span>Now</span> <span className='span_data'>Price : { HotelDetails.price }/Night</span></h3>
                                        </div>
                                        <form action="" className='php-email-form' method="POST">
                                            <div className='form-group'>
                                            <input className="form-control" name="name" id="name" placeholder="Your Name" type="text" required="" />
                                            </div> 
                                            <div className='form-group'>
                                            <input className="form-control" name="email_id" id="name" placeholder="Email ID" type="text" required="" />
                                            </div> 
                                            <div className='form-group'>
                                            <input className="form-control" name="phone_number" id="name" placeholder="Phone Number" type="text" required="" />
                                            </div> 
                                            <div className='form-group'>
                                            <input className="form-control" name="travel_date" id="name" placeholder="Travel Date" type="date" required="" />
                                            </div>
                                            <div className='form-group'>
                                              <select className='form-control'>
                                                <option value="0">Adults</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                              </select>
                                            </div>
                                            <div className='form-group'>
                                            <select className='form-control'>
                                                <option value="0">Child</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                              </select>
                                            </div> 
                                            <div className='form-group'>
                                              <textarea className='form-control' placeholder='Message'></textarea>
                                            </div>                                  
                                            
                                           
                                            <button type="submit" id="enquiry_send" className="gkbtn"> Send </button>
                                        </form>
                                        
                                      
                                    </div>
          </div>
        </div>

      </div>
    </section>
</main>
    </>
  )
}
