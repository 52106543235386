import React,{ useState, useEffect } from 'react'

export default function Hotel() {
  const headerStyle = {
    backgroundImage: "url('/images/page-title-bg.jpg')",
    // Add other styles as necessary, e.g., height, backgroundSize, etc.
  };
    const [hotel, homehotel] = useState([]);
    useEffect(() => {
        // Define your API endpoint
        const apiUrl = 'https://chalophir.com/admin/api/hotelsgetapi';
    
        fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        //console.log('API Response:', data);

      // Ensure data is an array before setting the state
      if (data.status === 1 && Array.isArray(data.hotel_list)) {
        homehotel(data.hotel_list);
      } else {
        console.error('Invalid data format:', data);
      }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <>
    <main id="main" data-aos="fade-in" className="aos-init aos-animate">

    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center" style={headerStyle}>
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>Hotel</h2>
              {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Hotel</li>
          </ol>
        </div>
      </nav>
    </div>
<section id="courses" className="courses">
  <div className="container aos-init aos-animate" data-aos="fade-up">

    <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
    {hotel.map(hoteldata => (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch hotelclass" key={hoteldata.id}>
        <div className="course-item">
              <img src={hoteldata.hotel_image} className="img-fluid" alt="..." />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 data-toggle="modal" data-target="#exampleModal">Enquiry Now</h4>
                  <p className="price">INR {hoteldata.price}/Night</p>
                </div>

                <h3><a href={'hotel-details/'+hoteldata.slug}>{hoteldata.hotel_name}</a></h3>
              </div>
            </div>
      </div>
        ))}
    

      
    </div>

  </div>
</section>

</main>
    </>
  )
}
