import React from 'react'
import './travellers.css'

export const Travellers = ({close, travellersAndClass, setTravellersAndClass}) => {
    
  return (
    <div className='mobile-travellers'>
        <div className='search-main-conatiner-mobile-navigation mobile-travellers-navigation'>
            <div 
                onClick={close}
                className='font-weight-bold'>
                <i class="bi bi-x"></i>
            </div> 
            <span className='font-weight-bold'>TRAVELLERS & CLASS</span>
        </div>
        <div className='mobile-travellers-common'>
            <p>ADD NUMBER OF TRAVELLERS</p>
            <div className='mobile-travellers-no'>
                <div>
                    <p>Adults</p>
                    <p>12 yrs above on the day of travel</p>
                </div>
                <div>
                    <span className={`${travellersAndClass.adults>0?'active':null}`}
                    onClick={()=>{
                        if(travellersAndClass.adults >= 1)
                        setTravellersAndClass(pre=>({...pre,adults:travellersAndClass.adults--}))}}
                    >-</span>
                    <span>{`${travellersAndClass.adults>9?travellersAndClass.adults:`0${travellersAndClass.adults}`}`}</span>
                    <span
                        onClick={()=>setTravellersAndClass(pre=>({...pre,adults:travellersAndClass.adults++}))}
                    >+</span>
                </div>
            </div>
            <div className='mobile-travellers-no'>
                <div>
                    <p>Children</p>
                    <p>2 - 12 yrs above on the day of travel</p>
                </div>
                <div>
                    <span className={`${travellersAndClass.children>0?'active':null}`}
                    onClick={()=>{
                        if(travellersAndClass.children >= 1)
                        setTravellersAndClass(pre=>({...pre,children:travellersAndClass.children--}))}}
                    >-</span>
                    <span>{`${travellersAndClass.children>9?travellersAndClass.children:`0${travellersAndClass.children}`}`}</span>
                    <span
                        onClick={()=>setTravellersAndClass(pre=>({...pre,children:travellersAndClass.children++}))}
                    >+</span>
                </div>
            </div>
            <div className='mobile-travellers-no'>
                <div>
                    <p>Infants</p>
                    <p>Under 2 yrs above on the day of travel</p>
                </div>
                <div>
                    <span className={`${travellersAndClass.infants>0?'active':null}`}
                    onClick={()=>{
                        if(travellersAndClass.infants >= 1)
                        setTravellersAndClass(pre=>({...pre,infants:travellersAndClass.infants--}))}}
                    >-</span>
                    <span>{`${travellersAndClass.infants>9?travellersAndClass.infants:`0${travellersAndClass.infants}`}`}</span>
                    <span
                        onClick={()=>setTravellersAndClass(pre=>({...pre,infants:travellersAndClass.infants++}))}
                    >+</span>
                </div>
            </div>
        </div>

        <div className='mobile-travellers-common'>
            <p>ADD NUMBER OF TRAVELLERS</p>
            <div className='search-main-conatiner-mobile-content-fare-type' id='mobile-travellers-class'>
                <div>
                {['Economy/Premium Economy','First Class','Premium Economy','Business'].map((e,i)=>{
                      return <span key={i} 
                      onClick={()=>setTravellersAndClass(pre=>({...pre,class:e}))}
                      style={e===travellersAndClass.class ? {backgroundColor:"#e03a3c",color:'#fff'}:null}>{e}</span>
                    })}
                </div>
            </div>

        </div>
        <div id='search-button-container-mobile ' className='search-button-container-mobile-travellers'>
                <button
                    onClick={close}
                    id='search-button-mobile'>DONE
                </button>
            </div>
    </div>
  )
}
