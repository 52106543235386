import React,{ useState, useEffect } from 'react';

export default function Vissa() {
  const headerStyle = {
    backgroundImage: "url('/images/page-title-bg.jpg')",
    // Add other styles as necessary, e.g., height, backgroundSize, etc.
  };
  const styles = {
    marginLeft: '20px',
  };
      const [options, setOptions] = useState([]);
      const [selectedOption, setSelectedOption] = useState('');
      const [apiResult, setApiResult] = useState(null);
      const [states, setStates] = useState([]);
      const [formData, setFormData] = useState({
        country: '',
        purpose_of_travel: '',
        nationality: '',
        submission_center: '',
      });
      useEffect(() => {
        // Define your API endpoint
        const apiUrl = 'https://chalophir.com/admin/api/CountryList';
    
        fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        //console.log('API Response:', data);

      // Ensure data is an array before setting the state
      if (data.status === 1 && Array.isArray(data.countryList)) {
        setOptions(data.countryList);
      } else {
        console.error('Invalid data format:', data);
      }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
    
      const handleChange = event => {
        setSelectedOption(event.target.value);
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(formData)
        try {
          const formData = {
            country: e.target.country.value,
            purpose_of_travel: e.target.purpose_of_travel.value,
            nationality: e.target.nationality.value,
            submission_center: e.target.submission_center.value,
          }
         // console.log(formData)
          const response = await fetch('https://chalophir.com/admin/api/CheckList', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const data = await response.json();
          setApiResult(data);
          
        } catch (error) {
          console.error('Error:', error);
        }
        
      };
      const fetchStates = (country) => {
        // Replace this URL with your actual API endpoint for states
        fetch(`https://chalophir.com/admin/api/purposeoftravel/${country}`)
          .then(response => response.json())
          .then(data => setStates(data.purposeoofTravel))
          .catch(error => console.error('Error fetching states:', error));
      };
      const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setFormData(prevData => ({ ...prevData, country: selectedCountry }));
    
        // Fetch states based on the selected country
        fetchStates(selectedCountry);
    
        // Reset the selected state when the country changes
        setFormData(prevData => ({ ...prevData, state: '' }));
      };
     // console.log(apiResult.CheckList)
      
  return (
    <>
    <main id="main">
    <div class="breadcrumbs">
      <div class="page-header d-flex align-items-center" style={headerStyle}>
        <div class="container position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 text-center">
              <h2>Visa Checklist</h2>
              
              {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Visa Checklist</li>
          </ol>
        </div>
      </nav>
    </div>
        <section id="contact" className="contact">
        <div data-aos="fade-up" className="aos-init aos-animate">
        <form className="php-email-form" method="POST" onSubmit={handleSubmit}>
          <div className='row'>
          
            <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className="form-group">
            <select className='form-control' name="country" value={formData.country}  onChange={handleCountryChange}>
        <option>Select Country</option>
        {options.map(country => (
          <option key={country.country_id} value={country.country_id}>
            {country.country_name}
          </option>
        ))}
    </select>
    </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-6'>
            <div className="form-group">
            <select className='form-control' name="purpose_of_travel" value={formData.purpose_of_travel}  onChange={event => setFormData(prevData => ({ ...prevData, purpose_of_travel: event.target.value }))}>
        <option>Select Purpose of Travel</option>
        {states.length > 0 ? (
          states.map(state => (
            <option key={state.purpose_of_travel} value={state.purpose_of_travel}>
              {state.purpose_of_travel}
            </option>
          ))
        ) : (
          <option selected value="" disabled>
            Not Available
          </option>
        )}
    </select>
    </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-6'>
            <div className="form-group">
            <select className='form-control' name="nationality" value={formData.nationality}  onChange={event => setFormData(prevData => ({ ...prevData, nationality: event.target.value }))}>
        <option>Select Nationality</option>
        <option value='Indian'>Indian</option>
    </select>
    </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-6'>
            <div className="form-group">
            <select className='form-control' name="submission_center" value={formData.submission_center}  onChange={event => setFormData(prevData => ({ ...prevData, submission_center: event.target.value }))}>
        <option>Select Submission Centre</option>
        <option value='New Delhi'>New Delhi</option>
    </select>
    </div>
            </div>
            <div className='col-sm-12 col-md-3 col-lg-3'>
            <button type="submit">Check Requirements</button>
            </div>
             


          </div>
          </form>
 
{apiResult && apiResult.CheckList && apiResult.CheckList.length > 0 ? (
        // Render your data when it is available
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                <h2 className='details_heading'>Details</h2>
                  <div className='first_div'>
                  <table className='table table-stripped table-hover table-striped'>
                  <thead>
                    <th>Destination</th>
                    <th>Purpose of Travel</th>
                    <th>Nationality</th>
                    <th>Submission Center</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ apiResult.CheckList[0].country_name }</td>
                      <td>{ apiResult.CheckList[0].purpose_of_travel }</td>
                      <td>{ apiResult.CheckList[0].nationality }</td>
                      <td>{ apiResult.CheckList[0].submission_center }</td>
                    </tr>
                    </tbody>
                  </table>
                  </div>
                  <div className='second_div'>
                  <table className="table table-stripped table-hover table-striped">
                      <thead>
                        <tr>
                          <th colSpan="5">PDF Download</th>                
                        </tr>
                      </thead>
                      <tbody>
                      {apiResult.CheckList[0].Authorization_form1.length > 0 ? (
                      <tr>
                      <td><a target="_blank" href= {apiResult.CheckList[0].Authorization_form1}>{apiResult.CheckList[0].label1} </a></td>    
                    </tr>
                      ) : (
                        <></>
                      )}
                      {apiResult.CheckList[0].Authorization_form2.length > 0 ? (
                      <tr>
                      <td><a target="_blank" href= {apiResult.CheckList[0].Authorization_form2}>{apiResult.CheckList[0].label2}</a></td>    
                    </tr>
                      ) : (
                        <></>
                      )}
                      {apiResult.CheckList[0].Authorization_form3.length > 0 ? (
                      <tr>
                      <td><a target="_blank" href= {apiResult.CheckList[0].Authorization_form3}>{apiResult.CheckList[0].label3} </a></td>    
                    </tr>
                      ) : (
                        <></>
                      )}
                      {apiResult.CheckList[0].Authorization_form4.length > 0 ? (
                      <tr>
                      <td><a target="_blank" href= {apiResult.CheckList[0].Authorization_form4}>{apiResult.CheckList[0].label4} </a></td>    
                    </tr>
                      ) : (
                        <></>
                      )}
                      </tbody>
                    </table>
                    </div>
                    
                    <div className='pragaraph_p' dangerouslySetInnerHTML={{ __html: apiResult.CheckList[0].description }}></div>
                </div>
            </div>
        </div>
      ) : (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                  
                <p style={styles} className='details_heading'>Result not found</p>
                  
                </div>
              </div>
            </div>
        
      )}
        </div>

        </section>
    
      </main>
    </>
  )
}
