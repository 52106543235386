import React from 'react'

export default function Apply() {
    
  return (
    <>
    <main id="main" data-aos="fade-in" className="aos-init aos-animate">
   <div className='pageSectionsContainer'>
      <section className='ApplyFormSection_sectionContainer__kYPQD'>
         <div className='css-14ne836'>
            <div className="ApplyFormSection_card__8wu6W css-wz2b58">
               <div className='FormSteper_customSteperStyle__45AOL css-ogvvg2'>
                  <div className="css-166ciyp">
                     <span className="MuiStepLabel-alternativeLabel css-1abj2s5">
                        <span className="MuiStepLabel-iconContainer Mui-active MuiStepLabel-alternativeLabel css-a5nipc">
                           <div className="css-uxwysf">
                              <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FeedIcon">
                                 <path d="M16 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8zM7 7h5v2H7zm10 10H7v-2h10zm0-4H7v-2h10zm-2-4V5l4 4z"></path>
                              </svg>
                           </div>
                        </span>
                        <span className="MuiStepLabel-labelContainer MuiStepLabel-alternativeLabel css-h2cmlr">
                           <span className="MuiStepLabel-label Mui-active MuiStepLabel-alternativeLabel css-ek8oaw">
                              <p className="css-171ap4h">Application</p>
                           </span>
                        </span>
                     </span>
                  </div>
                  <div className="MuiStep-root MuiStep-horizontal MuiStep-alternativeLabel css-166ciyp">
                     <div className="MuiStepConnector-root MuiStepConnector-horizontal MuiStepConnector-alternativeLabel Mui-disabled css-uulo2i"><span className="MuiStepConnector-line MuiStepConnector-lineHorizontal css-95m0ql"></span></div>
                     <span className="MuiStepLabel-root MuiStepLabel-horizontal Mui-disabled MuiStepLabel-alternativeLabel css-1abj2s5">
                        <span className="MuiStepLabel-iconContainer Mui-disabled MuiStepLabel-alternativeLabel css-a5nipc">
                           <div className="css-bq6l67">
                              <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FactCheckIcon">
                                 <path fill-rule="evenodd" d="M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M10 17H5v-2h5zm0-4H5v-2h5zm0-4H5V7h5zm4.82 6L12 12.16l1.41-1.41 1.41 1.42L17.99 9l1.42 1.42z"></path>
                              </svg>
                           </div>
                        </span>
                        <span className="MuiStepLabel-labelContainer MuiStepLabel-alternativeLabel css-h2cmlr">
                           <span className="MuiStepLabel-label Mui-disabled MuiStepLabel-alternativeLabel css-ek8oaw">
                              <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-171ap4h">Summary</p>
                           </span>
                        </span>
                     </span>
                  </div>
                  <div className="MuiStep-root MuiStep-horizontal MuiStep-alternativeLabel css-166ciyp">
                     <div className="MuiStepConnector-root MuiStepConnector-horizontal MuiStepConnector-alternativeLabel Mui-disabled css-uulo2i"><span className="MuiStepConnector-line MuiStepConnector-lineHorizontal css-95m0ql"></span></div>
                     <span className="MuiStepLabel-root MuiStepLabel-horizontal Mui-disabled MuiStepLabel-alternativeLabel css-1abj2s5">
                        <span className="MuiStepLabel-iconContainer Mui-disabled MuiStepLabel-alternativeLabel css-a5nipc">
                           <div className="css-bq6l67">
                              <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PriceCheckIcon">
                                 <path d="M12 13V9c0-.55-.45-1-1-1H7V6h5V4H9.5V3h-2v1H6c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h4v2H5v2h2.5v1h2v-1H11c.55 0 1-.45 1-1m7.59-.48-5.66 5.65-2.83-2.83-1.41 1.42L13.93 21 21 13.93z"></path>
                              </svg>
                           </div>
                        </span>
                        <span className="MuiStepLabel-labelContainer MuiStepLabel-alternativeLabel css-h2cmlr">
                           <span className="MuiStepLabel-label Mui-disabled MuiStepLabel-alternativeLabel css-ek8oaw">
                              <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-171ap4h">Enquiry</p>
                           </span>
                        </span>
                     </span>
                  </div>
               </div>
               <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 ApplyFormSection_heading___4xHr css-15j76c0">
                  <h5 className="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom css-3m9515"><img src="https://flagcdn.com/w40/ae.png" alt="flag" width="20" height="50" />UAE APPLICATION FORM</h5>
               </div>
               <div className="ApplyFormContent_formConatainer__jDsqK MuiBox-root css-0">
                  <form>
                     <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 css-isbt42">
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
                           <h5 className="MuiTypography-root MuiTypography-h5 css-1n56o3v">Traveler Information</h5>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className="form-group newclass"><input type="text" className="form-control" value="" placeholder='First Name' /></div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className="form-group newclass"><input type="text" className="form-control" value="" placeholder='Last Name' /></div>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className="form-group newclass">
                                <select className="form-control">
                                    <option>Select Visa</option>
                                </select>
                                </div>
                            </div>
                            
                        </div>
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
                           <h5 className="MuiTypography-root MuiTypography-h5 css-1n56o3v">Contact Details</h5>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className="form-group newclass"><input type="text" className="form-control" value="" placeholder='Phone Number' /></div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className="form-group newclass"><input type="text" className="form-control" value="" placeholder='Email ID' /></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <select className='form-control'>
                                        <option>Select Nationality</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <select className='form-control'>
                                        <option>Current Address</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <input type="text" className='form-control' name="" placeholder='Profession' />
                                </div>
                            </div>
                            
                        </div>

                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
                           <h5 className="MuiTypography-root MuiTypography-h5 css-1n56o3v">Passport Details</h5>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <input type="date" className='form-control' name="" placeholder='Travel Date' />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <input type="text" className='form-control' name="" placeholder='Passport Number' />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <input type="date" className='form-control' name="" placeholder='Passport Expiry Date' />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 col-md-6">
                                <div className="form-group newclass">
                                    <select className="form-control">
                                        <option>Purpose of travel</option>
                                        <option>Tourism</option>
                                        <option>Business</option>
                                        <option>Others</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
                           <h5 className="MuiTypography-root MuiTypography-h5 css-1n56o3v">Attachments</h5>
                        </div>
                        <em className="MuiTypography-root MuiTypography-body1 ApplyFormContent_attachmentsRules__OLa2R css-1wrz314">File Size Max 40MB.<br />Photo without eyglasses<br />passport validity is 6 months should be<br />Accept Files PNG, JPG, JPEG, and PDF.<br /><b>Please Note: </b>Only ORDINARY PASSPORT Is Acceptable Travel Docs, Service Passport &amp; etc... Is Not Applicable.</em>
                        
                        
                        <div className="MuiGrid-root MuiGrid-container MuiGrid-item css-1ul47bz">
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 FileUploadV2_uploadField__JJVi0 css-1weff7v">
                        <input id="file_passport_size_picture" accept=".jpg, .jpeg, .png, .pdf" type="file" name="passport_size_picture" style={{display: 'none'}} />
                        <div className="FileUploadV2_uploadFieldContent__WZZ9I MuiBox-root css-0" tabindex="0">
                        <div className="FileUploadV2_fileUploadIcon__AB0x1 MuiBox-root css-0">
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="QuestionMarkIcon" aria-label="passport size picture with white background without any glasses">
                        <path d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15M14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2"></path>
                        </svg>
                        </div>
                        <label for="file_passport_size_picture" className="FileUploadV2_labelStyleContainer__jdqs4">
                        <div className="FileUploadV2_documentIcon__aUO8N MuiBox-root css-0">
                        <img src="https://www.edubaivisa.ae/icons/picture.svg" alt="Picture" /></div>
                        <span className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-fullWidth FileUploadV2_textStyle__8C2It css-13bc3to" tabindex="0" role="button">
                        <div className="MuiBox-root css-0">
                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileUploadIcon">
                                <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"></path></svg></div>
                                <p className="MuiTypography-root MuiTypography-body1 css-1ruiwe2">Picture</p>
                                <span className="MuiTouchRipple-root css-w0pj6f"></span></span></label></div>
                                </div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 FileUploadV2_uploadField__JJVi0 css-1weff7v">
                                    <input id="file_passport_copy" accept=".jpg, .jpeg, .png, .pdf" type="file" name="passport_copy" style={{display:'none'}} />
                                    <div className="FileUploadV2_uploadFieldContent__WZZ9I MuiBox-root css-0" tabindex="0">
                                        <div className="FileUploadV2_fileUploadIcon__AB0x1 MuiBox-root css-0">
                                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="QuestionMarkIcon" aria-label="Passport Copy">
                                                <path d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15M14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2"></path></svg></div>
                                                <label for="file_passport_copy" className="FileUploadV2_labelStyleContainer__jdqs4">
                                                    <div className="FileUploadV2_documentIcon__aUO8N MuiBox-root css-0"><img src="https://www.edubaivisa.ae/icons/passport.svg" alt="Passport" /></div>
                                                    <span className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-fullWidth MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-fullWidth FileUploadV2_textStyle__8C2It css-13bc3to" tabindex="0" role="button">
                                                        <div className="MuiBox-root css-0"><svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileUploadIcon">
                                                            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"></path></svg></div>
                                                            <p className="MuiTypography-root MuiTypography-body1 css-1ruiwe2">Passport</p><span className="MuiTouchRipple-root css-w0pj6f"></span></span></label></div></div></div>
                        
                        
                        
                        
                        
                        
                        
                                                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 ApplyFormContent_termsCondition__jNRX4 css-15j76c0"><label for="terms_condition"><span className="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium css-1wfxyx"><input className="PrivateSwitchBase-input css-1m9pwf3" id="terms_condition" data-indeterminate="false" type="checkbox" name="terms_condition" />&nbsp;&nbsp;<span className="MuiTouchRipple-root css-w0pj6f"></span></span><p className="MuiTypography-root MuiTypography-body1 css-1ruiwe2">I have read and agree to the <a className="ApplyFormContent_termsLink__WaFI_" target="_blank" href="">Terms and Conditions</a></p></label></div>

                                                        <div className="ApplyFormContent_formSubmitButton__7YkKM MuiBox-root css-uboc2l"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-kurovt" tabindex="0" type="submit">Next <span>→</span><span className="MuiTouchRipple-root css-w0pj6f"></span></button></div>
                        
                        
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </section>
   </div>
</main>
    </>
  )
}
