import React from 'react'
import Subscriber from '../Home/Subscriber'
export default function Contact() {
    const divStyle = {
        marginTop: '82px'
    };
    const divStyle1 = {
        backgroundPosition: '50% 8px'
      };
      const divStyle2 = {
        display: 'none'
      };
  return (
    <>
    <main id="main">
<div className="breadcrumbs aos-init aos-animate" data-aos="fade-in">
  <div className="container">
    <h2>Contact Us</h2>
    {/* <p>Est dolorum ut non facere possimus quibusdam eligendi voluptatem. Quia id aut similique quia voluptas sit quaerat debitis. Rerum omnis ipsam aperiam consequatur laboriosam nemo harum praesentium. </p> */}
  </div>
</div>
<section id="contact" className="contact">
  <div data-aos="fade-up" className="aos-init aos-animate">
    
    <iframe style={{ height: '350px', width: '100%', border: '0' }} frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=12a/11,+Saraswati+Marg,+Block+12A,+WEA,+Karol+Bagh,+New+Delhi,+Delhi+110005,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
  </div>
</section>
<Subscriber/>
</main>
    </>
  )
}
