import React,{ useState, useEffect } from 'react'

export default function Blogsection() {
      const [blog, latestblog] = useState([]);
      useEffect(() => {
        // Define your API endpoint
        const apiUrl = 'https://chalophir.com/admin/api/bloglist';
    
        fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        //console.log('API Response:', data);

      // Ensure data is an array before setting the state
      if (data.status === 1 && Array.isArray(data.blog_list)) {
        latestblog(data.blog_list);
      } else {
        console.error('Invalid data format:', data);
      }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <>
    <section id="popular-courses" className="courses">
      <div className="container aos-init aos-animate" data-aos="fade-up">

        <div className="section-title">
          <h2>Latest Blog</h2>
        </div>

        <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">

        {blog.map(blog_data => (
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={blog_data.id}>
            <div className="course-item">
              <a href={'blog-details/'+blog_data.slug}><img src={blog_data.blog_image} className="img-fluid" alt={blog_data.blog_title.substring(0, 27)} /></a>
              <div className="course-content">
                <h3><a href={'blog-details/'+blog_data.slug}>{blog_data.blog_title.substring(0, 27)}</a></h3>
                <p>{blog_data.short_description}</p>
              </div>
            </div>
          </div>
   ))}
          
          

        </div>

      </div>
    </section>
    </>
  )
}
