import React from 'react'
import Footer from '../../Components/Footer'

export default function NotFound() {
    const divStyle = {
        minHeight: '264px',
      };
      const divStyle1 = {
        backgroundPosition: '50% 0px',
      };
  return (
    <>
    <div id="wrap">
        <section className="page-not-found" style={divStyle}>
            <div className="bg-parallax bg-1" style={divStyle1}></div>
            <div className="container">
                <div className="logo-page text-center">
                    <a href="/" title="">
                    <h1><span className='banner-text'>CHALO</span><span className='banner-cholophir'>PHIR</span></h1>
                    </a>
                </div>
                <div className="page-body text-center">
                    <div className="item-content">
                        <div className="image-wrap">
                            <img src="images/404.png" alt="" />
                        </div>
                        <h4>We’re really sorry!</h4>
                        <p>Something went wrong trying to display the page,<br />Please try one of these instead</p>
                    </div>
                    <div className="item-footer">
                        <a href="/" className="awe-btn awe-btn-5 arrow-right awe-btn-small text-uppercase">GO home</a>
                        
                    </div>

                </div>
            </div>


        </section>
       <Footer/>
    </div>
    </>
  )
}
