export const tripTypeData = [
    {  
      key:'oneWay',
      text: 'One Way'
    },
    {
      key:'roundTrip',
      text: 'Round Trip'
    },
    {
      key: "multiCity",
      text: 'Multi City'
    }
]

export const fareTypeData = [
    
    {  
      key:'regular',
      text: 'Regular'
    },
    {
      key:'armed',
      text: 'Armed Forces'
    },
    {
      key: "student",
      text: 'Student'
    },
    {
      key: "senior",
      text: 'Senior Citizen'
    },
    {
      key: "doctors",
      text: 'Doctors & Nurses'
    }
]
    
export const airportSearchResultData = {
      "results": {
          "SUGGESTIONS": {
              "title": "SUGGESTIONS",
              "data": [
                  {
                      "iata": "DEL",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "New Delhi",
                      "airportName": "Indira Gandhi International Airport",
                      "extraData": {
                          "tag": {
                              "title": "",
                              "icon": "",
                              "icons": null,
                              "nearbyHeader": "\u003cfont color='#4A4A4A'\u003e1 Nearby Airports found | within 200 km\u003c/font\u003e",
                              "bgColors": [
                                  "#FBEEC2"
                              ]
                          }
                      },
                      "groupData": [
                          {
                              "iata": "AGR",
                              "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                              "cityName": "Agra",
                              "airportName": "Agra Airport",
                              "distanceInfoText": "178 km from New Delhi",
                              "country": "India",
                              "countryCode": "IN"
                          }
                      ],
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "JAI",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Jaipur",
                      "airportName": "Jaipur Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "IXC",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Chandigarh",
                      "airportName": "Chandigarh Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "DED",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Dehradun",
                      "airportName": "Jolly Grant Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "GWL",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Gwalior",
                      "airportName": "Gwalior Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "PGH",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Pantnagar",
                      "airportName": "Pantnagar Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BUP",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Bathinda",
                      "airportName": "Bathinda Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BEK",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Bareilly",
                      "airportName": "Bareilly Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "LUH",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Ludhiana",
                      "airportName": "Sahnewal Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BJX",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Leon/Guanajuato",
                      "airportName": "Del Bajio",
                      "country": "Mexico",
                      "countryCode": "MX"
                  },
                  {
                      "iata": "PMV",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Porlamar",
                      "airportName": "DelCaribe Marino",
                      "country": "Venezuela",
                      "countryCode": "VE"
                  },
                  {
                      "iata": "DRT",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Del Rio",
                      "airportName": "Del Rio Intl",
                      "country": "United States",
                      "countryCode": "US"
                  },
                  {
                      "iata": "YWJ",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Deline",
                      "airportName": "Deline-NT",
                      "country": "Canada",
                      "countryCode": "CA"
                  },
                  {
                      "iata": "ESC",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Escanaba",
                      "airportName": "Delta County Arpt-MI",
                      "country": "United States",
                      "countryCode": "US"
                  },
                  
                  {
                      "iata": "DED",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Dehradun",
                      "airportName": "Jolly Grant Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "GWL",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Gwalior",
                      "airportName": "Gwalior Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "PGH",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Pantnagar",
                      "airportName": "Pantnagar Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BUP",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Bathinda",
                      "airportName": "Bathinda Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BEK",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Bareilly",
                      "airportName": "Bareilly Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "LUH",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Ludhiana",
                      "airportName": "Sahnewal Airport",
                      "country": "India",
                      "countryCode": "IN"
                  },
                  {
                      "iata": "BJX",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Leon/Guanajuato",
                      "airportName": "Del Bajio",
                      "country": "Mexico",
                      "countryCode": "MX"
                  },
                  {
                      "iata": "PMV",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Porlamar",
                      "airportName": "DelCaribe Marino",
                      "country": "Venezuela",
                      "countryCode": "VE"
                  },
                  {
                      "iata": "DRT",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Del Rio",
                      "airportName": "Del Rio Intl",
                      "country": "United States",
                      "countryCode": "US"
                  },
                  {
                      "iata": "YWJ",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Deline",
                      "airportName": "Deline-NT",
                      "country": "Canada",
                      "countryCode": "CA"
                  },
                  {
                      "iata": "ESC",
                      "icon": "https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png",
                      "cityName": "Escanaba",
                      "airportName": "Delta County Arpt-MI",
                      "country": "United States",
                      "countryCode": "US"
                  }
              ]
          }
      },
      "sectionsOrder": {
          "NEARBY_AIRPORTS": 0,
          "MORE_CITIES": 0,
          "SUGGESTIONS": 1,
          "INCREDIBLE_INDIA": 0
      }
}