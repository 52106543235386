import React, { useMemo } from 'react'
import './airports.css'
import { airportSearchResultData } from '../../data'

export const Airports = ({close, to, setTo, from, setFrom, autoFocusTo,setautoFocusTo, autoFocusFrom, setautoFocusFrom}) => {
  const isMobile = useMemo(() => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i),[]) 

  return (
    <div className='mobile-airports border-1'>
        <div className='pb-3'>

            <div className='search-main-conatiner-mobile-content-common'
                onClick={()=>{
                    setautoFocusFrom(true)            
                    setautoFocusTo(false)            
                    }
                }
            >
                <div onClick={close}>
                    <i class="bi bi-arrow-left-short"></i>
                </div> 
                <div>
                    <p>FROM</p>
                    {autoFocusFrom?
                    <div onClick={()=>{}}><input type="text" placeholder='Enter City/Airport Name' autoFocus/></div>
                    :
                    <p className='search-main-conatiner-mobile-common-airport-name'><strong>{from.cityName}</strong><span> &nbsp; {from.iata}&nbsp; <span style={{fontWeight:"200"}}>{from.airportName}</span></span></p>
                    }
                </div>
            </div>
            <div className='search-main-conatiner-mobile-content-common'
            onClick={()=>{
                setautoFocusFrom(false)
                setautoFocusTo(true)
            }}
            >
                <div><i class="bi bi-airplane"></i></div>
                <div>
                    <p>To</p>
                    {autoFocusTo?
                    <div><input type="text" placeholder='Enter City/Airport Name' autoFocus/></div>
                    :
                    <p className='search-main-conatiner-mobile-common-airport-name'><strong>{to.cityName}</strong><span> &nbsp; {to.iata}&nbsp; <span style={{fontWeight:"200"}}>{to.airportName}</span></span></p>
                    }
                </div>
            </div>

        </div>
        {autoFocusFrom &&
            <div className='search-result-display' 
            style={{height: `${isMobile?'calc(76vh - 55px)':'76vh'}`, overflow:'auto',scrollbarWidth: "none" }}
            >
                <p className='search-result-display-title'>{airportSearchResultData.results?.SUGGESTIONS.title}</p>
                {airportSearchResultData.results.SUGGESTIONS.data.map((e,i)=>{
                    return <div key={i} className='search-result-display-item'
                    onClick={()=>{
                        setFrom(e)
                        if(to == null)
                        setautoFocusTo(true)
                        else
                        close()
                    }}
                    >
                        <div className='search-result-display-city-iata'>
                            <div className='d-flex gap-2 w-100'>
                            <img src={e.icon} alt="icon" />
                            <div>
                                <p id='search-result-display-city search-result-display-mobile'
                                style={{padding:"0px", margin:"0px"}}
                                >{e.cityName}</p>
                                <p id='search-result-display-airport'>{e.airportName}</p>
                            </div>
                            </div>
                            <p id='search-result-display-iata'>{e.iata}</p>
                        </div>
                    </div>
                })}
            </div>
        }
        {autoFocusTo &&
            <div className='search-result-display'
            style={{height: `${isMobile?'calc(76vh - 55px)':'76vh'}`, overflow:'auto',scrollbarWidth: "none" }}
            >
                <p className='search-result-display-title'>{airportSearchResultData.results?.SUGGESTIONS.title}</p>
                {airportSearchResultData.results.SUGGESTIONS.data.map((e,i)=>{
                    return <div key={i} className='search-result-display-item'
                    onClick={()=>{
                        setTo(e)
                        close()
                    }}
                    >
                        <div className='search-result-display-city-iata'>
                            <div className='d-flex gap-2 w-100'>
                            <img src={e.icon} alt="icon" />
                            <div>
                                <p id='search-result-display-city search-result-display-mobile'
                                style={{padding:"0px", margin:"0px"}}
                                >{e.cityName}</p>
                                <p id='search-result-display-airport'>{e.airportName}</p>
                            </div>
                            </div>
                            <p id='search-result-display-iata'>{e.iata}</p>
                        </div>
                    </div>
                })}
            </div>
        }

    </div>
  )
}
