import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer id="footer">

<div className="footer-top">
  <div className="container">
    <div className="row">

      <div className="col-lg-3 col-md-6 footer-contact">
      <h1 className="logo me-auto"><a href="/"><span className='first_logo'>CHALÔ</span><span className='second_logo'>PHIR</span></a></h1>
        <p>
        12A/11, Saraswati Marg, Block 12A, <br/>
        WEA, Karol Bagh,<br/>
        New Delhi, Delhi, 110005 <br/><br/>
          <strong>Phone:</strong> +91 95555 86666<br/>
          <strong>Email:</strong> chalophir.com@gmail.com
        </p>
      </div>

      <div className="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/about-us">About us</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/checklist">Visa Checklist</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/term-and-service">Terms of service</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/privacy-policy">Privacy policy</a></li>
        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <a href="/blogs">Blog</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/packages">Packages</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/contact-us">Contact Us</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/login">Login</a></li>
          <li><i className="bx bx-chevron-right"></i> <a href="/login">Signup</a></li>
        </ul>
      </div>

      <div className="col-lg-4 col-md-6 footer-newsletter">
        <h4>Join Our Newsletter</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <form action="" method="post">
          <input type="email" name="email" /><input type="submit" value="Subscribe" />
        </form>
        
      </div>

    </div>
  </div>
</div>

<div className="container d-md-flex py-4">

  <div className="me-md-auto text-center text-md-start">
    <div className="copyright">
      &copy; Copyright <strong><span>Chalophir</span></strong>. All Rights Reserved
    </div>
    
  </div>
  <div className="social-links text-center text-md-end pt-3 pt-md-0">
    <a href="https://twitter.com/Chalophir_?s=08" className="twitter"><i className="bx bxl-twitter"></i></a>
    <a href="https://www.facebook.com/profile.php?id=100063697224034" className="facebook"><i className="bx bxl-facebook"></i></a>
    <a href="https://www.instagram.com/chalophir.co?utm_source=qr&igsh=NmdmYTlxMDE3cm5r" className="instagram"><i className="bx bxl-instagram"></i></a>
    <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
  </div>
</div>
</footer>
    </>
  )
}
