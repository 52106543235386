import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

export default function Subscriber() {
  
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log("Form submitted with values:", values);
    setSubmitting(true);
    axios.post('https://chalophir.com/admin/api/contact_us', values)
      .then(response => {
        console.log('Form submitted successfully:', response.data);
        setStatus({ success: true, message: 'Your message has been sent. Thank you!' });
        resetForm();
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        setStatus({ success: false, message: 'There was an error. Please try again later.' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <section id="contact" className="contact">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-title">
          <h2>Get In Touch With Us</h2>
        </div>
        <div className="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>12A/11, Saraswati Marg, Block 12A, WEA, Karol Bagh</p>
                  <p>New Delhi, Delhi, 110005</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>chalophir.com@gmail.com</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>+91 95555 86666</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, status }) => (
                <Form className="php-email-form">
                  <div className="row">
                    <div className="col form-group">
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                      />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                    <div className="col form-group">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                      />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                    />
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <Field
                      as="textarea"
                      name="message"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                    />
                    <ErrorMessage name="message" component="div" className="text-danger" />
                  </div>
                  <div className="my-3">
                    {isSubmitting && <div className="loading">Loading...</div>}
                    {status && (
                      <div className={status.success ? 'sent-message' : 'text-danger'}>
                        {status.message}
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" disabled={isSubmitting}>
                      Send Enquiry
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}
