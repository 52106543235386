import React,{ useState, useEffect } from 'react'

export default function Blog() {
    const [blog, blogdata] = useState([]);
    useEffect(() => {
        // Define your API endpoint
        const apiUrl = 'https://chalophir.com/admin/api/blogs';
    
        fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        //console.log('API Response:', data);

      // Ensure data is an array before setting the state
      if (data.status === 1 && Array.isArray(data.bloglist)) {
        blogdata(data.bloglist);
      } else {
        console.error('Invalid data format:', data);
      }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <>
    <main id="main" data-aos="fade-in" className="aos-init aos-animate">

<div className="breadcrumbs">
  <div className="container">
    <h2>Blogs</h2>
    <p></p>
  </div>
</div>
<section id="courses" className="courses">
  <div className="container aos-init aos-animate" data-aos="fade-up">

    <div className="row aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
    {blog.map(hoteldata => (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch hotelclass" key={hoteldata.id}>
        <div className="course-item">
              <img src={hoteldata.blog_image} className="img-fluid" alt="..." />
              <div className="course-content">
                

                <h3><a href={'blog-details/'+hoteldata.slug}>{hoteldata.blog_title}</a></h3>
                <p>{hoteldata.short_description}</p>
              </div>
            </div>
      </div>
        ))}
    

      
    </div>

  </div>
</section>

</main>
    </>
  )
}
