import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function BlogDetails() {
    let { str } = useParams(); // Access the str parameter from the URL
    const [blogDetails, setBlogDetails] = useState([]);
    const [recientblog, setRecinet] = useState([]);
    useEffect(() => {
        const fetchBlogDetails = async () => {
          try {
            const response = await fetch('https://chalophir.com/admin/api/blogdetails/'+str);
            if (!response.ok) {
              throw new Error('Failed to fetch blog details');
            }
            const data = await response.json();
            setBlogDetails(data.blog_details);
          } catch (error) {
            console.error('Error fetching blog details:', error);
          }
        };
        fetchBlogDetails();
      }, []);
      useEffect(() => {
        const fetchrecientBlogDetails = async () => {
          try {
            const response = await fetch('https://chalophir.com/admin/api/recentblog/'+str);
            if (!response.ok) {
              throw new Error('Failed to fetch blog details');
            }
            const data = await response.json();
            setRecinet(data.recentblog);
          } catch (error) {
            console.error('Error fetching blog details:', error);
          }
        };
        fetchrecientBlogDetails();
      }, []);
      //console.log(recientblog);
   
    return (
        <>
           <main id="main">


<div className="breadcrumbs aos-init aos-animate" data-aos="fade-in">
  <div className="container">
    <h2>Blog Detail</h2>
    
  </div>
</div>
<section id="course-details" className="course-details">
  <div className="container aos-init aos-animate" data-aos="fade-up">

    <div className="row">
      <div className="col-lg-8">
        <img src={blogDetails.blog_image} className="img-fluid" alt={ blogDetails.blog_title } />
        <h3>{ blogDetails.blog_title }</h3>
        <p dangerouslySetInnerHTML={{ __html: blogDetails.blog_description }}></p>
      </div>
      <div className="col-lg-4">

      
      <div className="aside-block">
              <h3 className="aside-title">Recent post</h3>
              <ul className="aside-links list-unstyled">
              {recientblog.map(blog_data => (
                 <li><a href={'/blog-details/'+blog_data.slug}><i className="bi bi-chevron-right"></i> {blog_data.blog_title}</a></li>
              ))}
                
                
              </ul>
            </div>

       

       

      </div>
    </div>

  </div>
</section>


</main>
        </>
    );
}